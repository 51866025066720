const $ = require('jquery')

$(() => {
  var $tosCheckbox = $('.tosCheckbox')
  var $confirmationButton = $('.tosRequired')

  function confirmTos () {
    if ($tosCheckbox.is(':checked')) {
      $confirmationButton.removeAttr('disabled')
    } else {
      $confirmationButton.attr('disabled', true)
    }
  }

  confirmTos()
  $tosCheckbox.change(confirmTos)
})
