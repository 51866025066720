'use strict'

/* eslint-disable new-cap */

function vivifyGraph (graph, statsParams) {
  // Specifying the possible module names here instead of using require('./' + graph.type) allows Webpack to create a more optimized package
  // The clumsy .default is required because those files are now ES6 modules (which is due to an issue with Babel described there)
  switch (graph.type) {
    case 'line': return new (require('./LineGraph').default)(graph, statsParams)
    case 'serviceBar': return new (require('./ServiceBarGraph').default)(graph, statsParams)
    case 'creditsUsed': return new (require('./CreditsUsedGraph').default)(graph, statsParams)
    default: throw new Error(`Unknown graph type "${graph.type}"`)
  }
}

module.exports = { vivifyGraph }
