'use strict'

// Agent number change requests

const $ = require('jquery')

module.exports = () => $(() => {
  const updateCountdowns = () => {
    $('.countdown-timer').each(function () {
      // By first dividing, we prevent issues with non-synchronized timer updates
      const remainingSeconds = Math.max(0, Math.floor(Number($(this).attr('data-countdown-target')) / 1000) - Math.floor(Date.now() / 1000))
      const minutes = Math.floor(remainingSeconds / 60)
      const seconds = remainingSeconds % 60
      $(this).text(minutes + ':' + seconds.toString().padStart(2, '0')).toggleClass('text-danger', remainingSeconds < 60)
    })
  }

  const originalTitle = document.title
  const updateTitle = () => {
    const numRelevant = $('.num-change-request[data-relevant=true]').length
    document.title = numRelevant ? `[${numRelevant}] ${originalTitle}` : originalTitle
  }

  updateCountdowns()
  updateTitle()

  setInterval(updateCountdowns, 1000)

  // Auto-refresh
  setInterval(function () {
    const prevRequests = Array.from($('.num-change-request:has(.claim-btn)')).map(x => $(x).attr('data-id'))
    $('.num-change-requests').load('?ajax=1 .num-change-requests > *', function () {
      updateCountdowns()
      updateTitle()
      const newRequests = Array.from($('.num-change-request:has(.claim-btn)')).map(x => $(x).attr('data-id'))
      if (newRequests.some(r => prevRequests.indexOf(r) === -1)) {
        try {
          $('#notification-sound').get(0).play()
        } catch (e) {
          console.warn(e) // May fail if user didn't interact with page first
        }
      }
    })
  }, 5000)
})
