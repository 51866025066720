'use strict'

window.pageMixins = {
  autoAccordion: require('./autoAccordion'),
  usersTable: require('./usersTable'),
  linesTable: require('./linesTable'),
  smsHistoryTable: require('./smsHistoryTable'),
  transactionsTable: require('./transactionsTable'),
  txCreationForm: require('./txCreationForm'),
  usdAccountTable: require('./usdAccountTable'),
  numChangeRequests: require('./numChangeRequests'),
  agentBgChangeRulesTable: require('./agentBgChangeRulesTable'),
  serviceListChangeHandler: require('./serviceListChangeHandler'),
  gatewayDetails: require('./gatewayDetails'),
  gatewayList: require('./gatewayList'),
  agentLinesTable: require('./agentLinesTable'),
  csRepLinesTable: require('./csRepLinesTable'),
  tmoAccountLockConfirmation: require('./tmoAccountLockConfirmation'),
  subscriptionsTable: require('./subscriptionsTable'),
  newSubscription: require('./newSubscription'),
  planStatusRenewalHelper: require('./planStatusRenewalHelper'),
  domainForm: require('./domainForm'),
  pricingTable: require('./pricingTable'),
  paymentMethodMarkupEditor: require('./paymentMethodMarkupEditor'),
  coinremitterCredentialsEditor: require('./coinremitterCredentialsEditor'),
  settingPopup: require('./settingPopup'),
  serviceCostTable: require('./serviceCostTable'),
  messagesTable: require('./messagesTable')
}
