'use strict'

// Plan status renewal helper mixin
// Makes the "mark plan as renewed" checkbox work

const $ = require('jquery')

module.exports = ({ newDate }) => $(() => {
  const $checkbox = $('#planRenewed')
  $checkbox.click(() => {
    const $form = $checkbox.closest('form')
    $form.find('[name=smsUsed], [name=planExpiration]').prop('readonly', $checkbox.prop('checked'))

    if ($checkbox.prop('checked')) {
      $form.find('[name=smsUsed]').val(0)
      $form.find('[name=planExpiration]').val(newDate)
    }
  })
})
