'use strict'

// Gateway list mixin

const $ = require('jquery')

module.exports = () => $(() => {
  $(document).on('click', '.rename-gateway-btn', e => {
    const $btn = $(e.target).closest('.rename-gateway-btn')
    const gatewayUrl = $btn.attr('data-gateway-url')
    const newName = window.prompt('Enter new name for gateway ' + gatewayUrl, $btn.attr('data-gateway-name'))
    if (newName === null) return
    const $form = $('#rename-gateway-form')
    $form.attr('action', '/admin/gateways/' + gatewayUrl + '/rename')
    $form.find('[name=newName]').val(newName)
    $form.submit()
  })

  $(document).on('click', '.multi-switch-in-btn', e => {
    const $btn = $(e.target).closest('.multi-switch-in-btn')
    const gatewayUrl = $btn.attr('data-gateway-url')
    const portsStr = window.prompt('Enter port range to schedule for switching in at ' + gatewayUrl + ' (e.g. 1.01, 1-4.01, 1.01-04, 1-4.01-04 - multiple separated by comma)')
    if (!portsStr) return
    const $form = $('#multi-switch-in-form')
    $form.attr('action', '/admin/gateways/' + gatewayUrl + '/multiSwitchIn')
    $form.find('[name=portsStr]').val(portsStr)
    $form.submit()
  })

  $(document).on('click', '.clear-error-states-btn', e => {
    const $btn = $(e.target).closest('.clear-error-states-btn')
    const gatewayUrl = $btn.attr('data-gateway-url')
    const portsStr = window.prompt('Enter port range to clear error states for at ' + gatewayUrl + ' (e.g. 1.01, 1-4.01, 1.01-04, 1-4.01-04 - multiple separated by comma)')
    if (!portsStr) return
    const $form = $('#clear-error-states-form')
    $form.attr('action', '/admin/gateways/' + gatewayUrl + '/clearErrorStates')
    $form.find('[name=portsStr]').val(portsStr)
    $form.submit()
  })
})
