'use strict'

/*
 * Support for automatic tables of content
 * Usage: Add <div class="table-of-contents"></div>
 *
 * Inside this div, links in a tree structure will be appended for all headings that follow
 * the "table-of-contents" element in its parent container. By default, the minimum heading
 * depth will be detected automatically (no useless extra indentions). Headings which do not
 * have an ID will get an automatic ID assigned.
 *
 * It is possible to select the exact depths of headings that should be considered, using
 * attributes "data-min-depth" and "data-max-depth".
 *
 * Example:
 * <div class="table-of-contents" data-min-depth="3" data-max-depth="5"></div>
 * In this example, only h3, h4 and h5 elements are listed.
 */

const $ = require('jquery')

let outerId = 0
$(() => {
  $('.table-of-contents').each(function () {
    const $this = $(this)
    outerId++

    const headings = 'h1, h2, h3, h4, h5, h6'
    const $nextEls = $this.nextAll()
    const $headings = $nextEls.filter(headings).add($nextEls.find(headings))

    let i = 0
    const tocEntries = []
    $headings.each(function () {
      const $heading = $(this)
      i++

      const id = $heading.attr('id') || `heading-${outerId}-${i}`
      $heading.attr('id', id)

      const matches = $heading.prop('tagName').match(/^H(\d+)$/i)
      const depth = matches ? Number(matches[1]) : 0

      tocEntries.push({
        title: $heading.text(),
        depth,
        id
      })
    })

    // Adjust min. depth
    const minDepth = Number($this.data('min-depth')) || tocEntries.reduce((agg, curr) => Math.min(agg.depth, curr.depth), Infinity)
    const maxDepth = Number($this.data('max-depth')) || Infinity
    for (const { title, depth, id } of tocEntries) {
      if (depth < minDepth || depth > maxDepth) continue
      $('<a/>').attr('href', '#' + id).text(title).css({display: 'block', marginLeft: (depth - minDepth + 1) * 20}).appendTo($this)
    }
  })
})
