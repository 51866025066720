const $ = require('jquery')
$(() => {
  $('#subscription').change(e => {
    const id = $('#subscription.selectpicker').val()
    if (id) {
      const $loader = $('#loader')
      $loader.fadeIn()

      $('#subscription-change-form').attr('action', `/subscriptions/${window.loggedInUser.id}/${id}/activate`).submit()
    }
  })
})
