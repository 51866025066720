'use strict'

// ES6-style import/export is used here since Babel doesn't allow us to mix "async" and CommonJS-style export syntax
import Graph from './Graph'

const moment = require('moment')
const echarts = require('echarts')
const { fillMissingDays } = require('../utils')

export default class LineGraph extends Graph {
  async loadData () {
    const rawData = await super.loadData()
    // Day filling is done here because it is later read out of rawData for KPI calculation
    // Therefore, it is logically part of rawData and not graphData, and hence it should be filled
    // in before rawData is even stored.
    fillMissingDays(rawData.data, this.fillDays, 0)
    return rawData
  }

  transformData (rawData) {
    const timestamps = Object.keys(rawData.data).sort((x, y) => x - y)
    return {
      date: timestamps.map(timestamp => {
        const date = moment(Number(timestamp))
        return date.format('MMM DD YYYY')
      }),
      data: timestamps.map(timestamp => rawData.data[timestamp])
    }
  }

  getChartConfig () {
    const chartConfig = super.getChartConfig()
    const daysDisplayed = 7

    chartConfig.tooltip.position = pt => [pt[0], '10%']

    return Object.assign(chartConfig, {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.graphData.date
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        axisLabel: { formatter: `${this.prefix || ''}{value}${this.suffix || ''}` }
      },
      dataZoom: [{
        type: 'inside',
        start: 100 - (daysDisplayed * 100 / this.graphData.date.length),
        end: 100
      }, {
        start: 0,
        end: 10,
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2
        }
      }],
      series: [
        {
          label: {
            normal: {
              show: true,
              formatter: '{b}'
            }
          },
          name: this.name,
          type: 'line',
          smooth: true,
          symbol: 'none',
          sampling: 'average',
          itemStyle: {
            color: '#fc4935'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#f8f163'
            }, {
              offset: 1,
              color: '#f98144'
            }])
          },
          data: this.graphData.data
        }
      ]
    })
  }

  getKpiValues () {
    return {
      today: this.rawData.data[moment().startOf('day').valueOf()],
      yesterday: this.rawData.data[moment().startOf('day').subtract(1, 'days').valueOf()],
      sameDayPrevWeek: this.rawData.data[moment().startOf('day').subtract(7, 'days').valueOf()]
    }
  }
}
