'use strict'

// SMS history table mixin
// Manages the table of SMS on the service history page

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')

// userId: ID of the user whose data is displayed
// subscription: A subscription ID if filtering for a single subscription is desired
module.exports = ({ userId, subscription }) => $(() => {
  setupImprovedDataTable($('#sms-history-table'), {
    columns: [
      { title: 'Received at', data: 'timestamp', defaultSort: 'desc', render: $.fn.dataTable.render.date() },
      { title: 'Sender', data: 'phoneNumber', render: $.fn.dataTable.render.text() },
      { title: 'Receiver', data: 'lineNumber', render: $.fn.dataTable.render.text() },
      { title: 'Text', data: 'text', defaultContent: '', className: 'break-word', render: $.fn.dataTable.render.text() }
    ],
    serverSide: true,
    ajax: `/services/${userId}/sms/dtAjax?subscription=${encodeURIComponent(subscription)}`,
    buttons: [$.fn.dataTable.defaults.buttons]
  })
})
