<script>

import { onMount } from 'svelte'
  import { formatDuration } from '../../../utils/usecasesTools';

export let tiers = []
export let usecases = {}
export let globalBasePricing
export let serviceTierAssignments = []
export let durations


let selectpickers

function createNewTableData () {
  return {serviceId: '_', tierId: ''}
}

function createTableRow (idx) {
  serviceTierAssignments = serviceTierAssignments.toSpliced(idx, 0, createNewTableData())
}

function removeTableRow (idx) {
  serviceTierAssignments = serviceTierAssignments.toSpliced(idx, 1)
}

function rehydrateSelectpicker (refresh) {
  //such hydration...
  setTimeout(() => {
    selectpickers = window.$('select.selectpicker.service-field')
    refresh ? selectpickers.selectpicker('refresh') : selectpickers.selectpicker()
  }, 0)
}

function removeDeletedUsecasePricing () {
  serviceTierAssignments = serviceTierAssignments.filter(el => !!usecases[el.serviceId] ||el.serviceId === '_')
  rehydrateSelectpicker(true)
}

onMount(rehydrateSelectpicker)
$: serviceTierAssignments, rehydrateSelectpicker(true)
$: usecases, removeDeletedUsecasePricing()
</script>


<h3>Service Tier Assignments</h3>
<small class="form-help text-muted">Any service without a service tier is treated as being in the default service tier.</small>

<div class="service-tier-editor clearfix">
  <table class="table">
  <thead>
  <tr>
  <th>Service</th>
  <th>Service Tier</th>
  {#each durations as duration}
    <th class="text-center">{formatDuration(duration)}</th>
  {/each}
  <th style="width: 120px"></th>
  </tr>
  </thead>
  <tbody>
  {#each serviceTierAssignments as row, i (row)}
    <tr>
      <td>
        <select class="form-control selectpicker service-field" title="Select Service" data-live-search="true" bind:value={row.serviceId}>
          <option hidden></option>
          {#each Object.entries(usecases) as [id, {name}]}
            <option class="usecases-option" data-subtext="" value={id} selected={row.serviceId === id}>{name}</option>
          {/each}
        </select>
      </td>
      <td>
        <select class="tier-field form-control" bind:value={row.tierId}>
          {#each tiers as {name, id, isDefaultTier}}
            {#if !isDefaultTier}
              <option value={id} selected={row.tierId === id} data-id={id}>{name}</option>
            {/if}
          {/each}
        </select>
      </td>
        {#each durations as duration}
          {@const tier = tiers.find(tier => tier.id === row.tierId) || {}}
          {@const tierPrice = (tier || {})[duration] || 0}
          {#if globalBasePricing}
            {@const basePrice = (globalBasePricing[(usecases[row.serviceId] || {}).nameBasedId] || {})[duration] || 0}
            {@const margin = tierPrice - basePrice}
            <td class="text-center"><span class="default-pricing h5">
              {#if margin < 0 && (tier.preventNegative || tierPrice === 0 && row.tierId)}
                <span class="text-muted strikethrough mr-1">
                  {typeof tierPrice === 'number' && !isNaN(tierPrice) && tierPrice !== 0 ? tierPrice.toFixed(2) : '?'}
                </span>
              {/if}
              <span class:text-danger={ row.serviceId === '_' || !row.tierId || margin < 0 && !tier.preventNegative && tierPrice} class:text-success={margin > 0}>
                {margin < 0 && tier.preventNegative ? (basePrice ? basePrice.toFixed(2) : '?' ) : ( tierPrice ? tierPrice.toFixed(2) : basePrice && row.tierId && basePrice.toFixed(2) || '?')} <i class="fa fa-phone-square"/>
              </span>
            </span></td>
          {:else}
            <td class="text-center">
              <span class:text-danger={ typeof tierPrice !== 'number' || isNaN(tierPrice) || tierPrice <= 0}>{typeof tierPrice === 'number' ? tierPrice.toFixed(2) : '?'} <i class="fa fa-phone-square"/></span>
            </td>
          {/if}
        {/each}
      <td>
        <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" on:click={() => createTableRow(i)}><i class="fa fa-plus"></i></button>
        <button type="button" title="Remove" class="remove-btn btn btn-outline-danger" on:click={() => removeTableRow(i)}><i class="fa fa-minus"></i></button>
      </td>
    </tr>
  {/each}

  </tbody>
  </table>
  <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" on:click={() => serviceTierAssignments = [...serviceTierAssignments, createNewTableData()]}><i class="fa fa-plus"></i> Add row</button>
  </div>



<style>
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  
  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}
</style>