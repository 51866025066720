'use strict'

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = () => $(() => {
  const $dataField = $('[name=paymentMethodMarkupPercent]')
  const $editor = $('#paymentMethodMarkupEditor')

  const valueObject = {}
  for (const [k, v] of $dataField.val().split('\n').map(x => x.trim()).filter(x => x).map(x => x.split('=').map(x => x.trim()))) {
    valueObject[k] = Number(v) || 0
  }

  function updateValueField () {
    $dataField.val(Object.entries(valueObject).map(([k, v]) => `${k}=${v}`).join('\n'))
  }

  function getEnabledPaymentMethods () {
    const enabledPaymentMethods = new Set()
    if ($('[name=paypalClientId]').val()) enabledPaymentMethods.add('PayPal')
    if ($('[name=stripePublicKey]').val()) enabledPaymentMethods.add('Stripe')
    if ($('[name=enableWebmoney]').prop('checked')) enabledPaymentMethods.add('WebMoney')
    if ($('[name=blockonomicsApiKey]').val() || $('[name=btcpayAccessToken]').val()) enabledPaymentMethods.add('BTC')
    if ($('[name=coinpaymentsPublicKey]').val() || $('[name=coinifyApiKey]').val() || $('[name=nowpaymentsApiKey]').val() || $('[name=coinremitterEnabled]').prop('checked') || $('[name=cryptomusMerchantId]').val()) {
      for (const coin of $('[name=acceptedCryptoCoins]').val().split('\n').map(c => c.trim()).filter(c => c)) {
        enabledPaymentMethods.add(coin.toUpperCase())
      }
    }
    return Array.from(enabledPaymentMethods)
  }

  $editor.on('input change', '[data-pm]', function () {
    const $this = $(this)
    const pm = $this.data('pm')
    const value = Number($this.val()) || 0
    if (!value) {
      delete valueObject[pm]
    } else {
      valueObject[pm] = value
    }
    updateValueField()
  })

  function updateEditor () {
    $editor.empty()
    for (const paymentMethod of getEnabledPaymentMethods()) {
      $editor.append(`
        <div class="form-group col-md-3">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">${encode(paymentMethod)}</span>
            </div>
            <input class="form-control form-control-sm text-right" placeholder="0" type="text" data-pm="${encode(paymentMethod)}" value="${valueObject[paymentMethod] || ''}" />
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
        </div>
      `)
    }
  }

  updateEditor()

  $('[name=paypalClientId], [name=stripePublicKey], [name=enableWebmoney], [name=blockonomicsApiKey], [name=btcpayAccessToken], [name=coinpaymentsPublicKey], [name=acceptedCryptoCoins]').on('input change', updateEditor)
})
