'use strict'

const $ = require('jquery')
const { customPaymentCalc } = require('../../../../utils/customPaymentCalc')

module.exports = ({ offers }) => $(() => {
  $('.new-tx-amount').on('input change', () => {
    const yourOriginalBalance = Number($('.balance-change.yours').data('original-balance'))
    const theirOriginalBalance = Number($('.balance-change.theirs').data('original-balance'))
    const amount = Number($('.new-tx-amount').val())

    if (amount) {
      const yourNewBalance = yourOriginalBalance - amount
      const theirNewBalance = theirOriginalBalance + amount
      $('.balance-change').show()
      $('.new-balance.yours').text(yourNewBalance === Infinity ? '∞' : yourNewBalance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })).css({
        color: amount <= 0 ? 'limegreen' : 'red'
      })
      $('.new-balance.theirs').text(theirNewBalance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })).css({
        color: amount > 0 ? 'limegreen' : 'red'
      })

      $('.submit').prop('disabled', yourNewBalance < 0 || theirNewBalance < 0)
    } else {
      $('.balance-change').hide()
    }

    // Update USD value according to user's pricing
    if (offers) {
      const usdValue = Math.sign(amount) * customPaymentCalc(Math.abs(amount), offers).price
      $('#usdValue').attr('placeholder', usdValue.toFixed(2))
    }
  })

  const updateUsdValueVisibility = () => {
    $('.usd-value-group').toggle($('#markAsSale').prop('checked'))
  }

  updateUsdValueVisibility()
  $('#markAsSale').on('click change', () => updateUsdValueVisibility())

  $('.tx-creation-form').submit(() => {
    // If nothing was entered we want to actually send the generated USD value to the server
    if (!$('#usdValue').val()) $('#usdValue').val($('#usdValue').attr('placeholder'))
  })
})
