const $ = require('jquery')
const { setViewState } = require('./utils')
const { vivifyGraph } = require('./graphs')

const statsParams = {}

function loadGraphs () {
  return (async () => { // This wrapping makes the function save to call without fear of unhandled promise rejection
    try {
      setViewState('loading')

      // Get selected owner ID
      statsParams.domainId = $('#domainId').val() || 'all'

      // To avoid a visual glitch on re-rendering immediately after re-showing, clear graph data first
      for (const graph of window.graphs) graph.clear()

      // Load data
      await Promise.all(window.graphs.map(graph => graph.load()))
      setViewState('ready')

      // Note: Graphs can be rendered only when their element is displayed! Otherwise the sizing will be incorrect
      for (const graph of window.graphs) graph.render()
    } catch (e) {
      console.error(e)
      setViewState('error')

      // Retry after 10 seconds
      setTimeout(loadGraphs, 10 * 1000)
    }
  })().catch(console.error)
}

$(() => {
  if ($('#domainId').length) {
    $('#domainId').selectpicker('refresh').change(loadGraphs)
  }

  for (const key in window.graphs) window.graphs[key] = vivifyGraph(window.graphs[key], statsParams)

  loadGraphs()
})
