function getListChanges (oldList, newList) {
  const oldArr = Array.isArray(oldList) ? oldList : oldList.split(/\r?\n/g).map(x => x.trim()).filter(x => !!x)
  const newArr = Array.isArray(newList) ? newList : newList.split(/\r?\n/g).map(x => x.trim()).filter(x => !!x)

  const newNotYetMatched = new Set(newArr)
  const changes = []

  let index = 0
  for (const prev of oldArr) {
    const foundAt = newArr.indexOf(prev)
    if (foundAt === -1) {
      const entryAtSamePosition = newArr[index + 1]
      if (!entryAtSamePosition || oldArr.includes(entryAtSamePosition) || !newNotYetMatched.has(entryAtSamePosition)) {
        changes.push({ type: 'deleted', value: prev })
      } else {
        changes.push({ type: 'renamed', value: prev, newValue: entryAtSamePosition })
        newNotYetMatched.delete(entryAtSamePosition)
      }
    } else {
      index = foundAt
      newNotYetMatched.delete(prev)
    }
  }

  for (const entry of newNotYetMatched) {
    changes.push({ type: 'added', value: entry })
  }

  return changes
}

function getUsecaseIdFromDisplayName (name) {
  return name.replace(/\W+/g, '_').replace(/^_|_$/g, '').toUpperCase()
}

function giveUsecasesActualIds (usecasesObj) {
  const betterUsecases = {}
  for (const [id, name] of Object.entries(usecasesObj)) {
    betterUsecases[Date.now() + Math.random().toString(36).substring(2)] = {name, nameBasedId: id}
  }
  return betterUsecases
}

function formatDuration (days) {
  return (days % 7 === 0) ? `${days / 7} week${Number(days) === 7 ? '' : 's'}` : `${days} day${Number(days) === 1 ? '' : 's'}`
}

module.exports = { getListChanges, getUsecaseIdFromDisplayName, giveUsecasesActualIds, formatDuration }
