'use strict'

const $ = require('jquery')

$(() => {
  if (window.lineForm) {
    const showTypeFields = function () {
      $('[data-only-for-type]').hide()
      const type = ($('#type').val() || '').toLowerCase()
      $('[data-only-for-type~="' + type + '"]').show()
    }

    $('#type').change(showTypeFields)
    showTypeFields()

    const updateZipField = function () {
      $('#zipCode').prop('readonly', $('#autoZip').prop('checked'))
      $('#number').change()
    }

    $('#autoZip').change(updateZipField)
    updateZipField()

    // This is just for admin convenience. The actual update of the ZIP code is done in the line hook.
    $('#number').change(function () {
      if ($('#autoZip').prop('checked')) {
        $('#zipCode').val('')
        $.getJSON('/admin-lines/getAutoZipCode', { number: $('#number').val() }).done(data => {
          $('#zipCode').val(data.zipCode)
        })
      }
    })
  }
})
