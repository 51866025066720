'use strict'

const $ = require('jquery')
require('datatables.net-bs4')
require('datatables.net-buttons-bs4')
require('datatables.net-select-bs4')

const { encode, ellipsis } = require('./utils')

// Set defaults for all our tables
Object.assign($.fn.dataTable.defaults, {
  dom: 'lftipr', // See https://datatables.net/reference/option/dom - note that by default we don't have buttons, only the "improved" tables get buttons
  pageLength: 10,
  order: [],
  buttons: [
    {
      text: 'Reload',
      className: 'btn-light',
      action: (e, dt, node, config) => {
        dt.ajax.reload()
      }
    }
  ]
})

// Remove btn-secondary style from buttons
$.fn.dataTable.Buttons.defaults.dom.button.className = 'btn'

// No weird alert boxes in case of errors - note that even missing data in a column would otherwise be considered an error
$.fn.dataTable.ext.errMode = 'none'

// Additional formatting helpers
$.fn.dataTable.render.ellipsis = cutoff => (data, type, row) => {
  if (type === 'display') {
    const str = data.toString() // cast numbers
    return ellipsis(str, cutoff)
  }

  // Search, order and type can use the original data
  return data
}

$.fn.dataTable.render.date = () => (data, type, row) => {
  if (!data) return 'Never'
  return new Date(data).toLocaleString()
}

// AJAX handler - wraps errors in order to display a message
function ajaxHandler ($el, url, columns, customAjaxDataHook) {
  let loadingCounter = 0

  return (query, callback, settings) => {
    const $container = $($el.DataTable().table().container())

    // First, remove any error which may be already displayed
    $container.children('.ajax-error').remove()

    // Change query column data because the backend doesn't know what the index means
    for (const sortField of query.order || []) {
      sortField.columnName = columns[sortField.column].data
    }

    // Show loader
    loadingCounter++
    $container.addClass('dt-loading')

    // Custom hook that allows adding/changing fields in the query (or returning an entirely new query)
    if (customAjaxDataHook) query = customAjaxDataHook(query) || query

    // Then, send the request
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      data: JSON.stringify(query),
      contentType: 'application/json; charset=utf-8'
    }).done(callback).fail((jqXHR, textStatus, e) => {
      // Error handler

      console.error('AJAX error', url, textStatus, e)
      const error = 'An error occured, please check your connection and try again.' // Maybe later we have something more useful

      // Show error box
      $container.find('.ajax-error').remove()
      $container.prepend(`
        <div class="alert alert-danger alert-dismissible fade show ajax-error" role="alert">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          ${encode(error)}
        </div>
      `)

      // Return error state
      callback({ // eslint-disable-line standard/no-callback-literal
        draw: query.draw,
        data: [],
        recordsTotal: 0,
        recordsFiltered: 0,
        error
      })
    }).always(() => {
      // Hide loader
      if (--loadingCounter === 0) {
        $container.removeClass('dt-loading')
      }
    })
  }
}

// Function to create an "improved" datatable
// This is called instead of directly creating the datatable and will take care of all the boilerplate that we don't want to repeat
function setupImprovedDataTable ($el, declaration) {
  if (!$el.length) return // Nothing was selected

  let dt

  // Enable buttons
  if (!declaration.dom) declaration.dom = 'B' + $.fn.dataTable.defaults.dom

  // Set up submenus if required
  // Note: subMenu `true` will cause a nameless submenu to appear
  const subMenus = {}
  // button.improved means it's a button that would not normally be compatible with DT's button extension but should still be added in the actions column
  const applicableButtons = declaration.buttons.filter(button => button.extend === 'selected' || button.improved)
  applicableButtons.forEach((button, index) => {
    declaration.buttons.splice(declaration.buttons.indexOf(button), 1)

    button.index = index

    if (button.subMenu) {
      subMenus[button.subMenu] = subMenus[button.subMenu] || []
      subMenus[button.subMenu].push(button)
    }
  })

  // Allow custom href behavior also for normal buttons (in a slightly different way - not using actual links)
  const normalButtons = declaration.buttons.filter(button => !applicableButtons.includes(button))
  for (const button of normalButtons) {
    if (button.href) {
      const oldAction = button.action
      button.action = function (e, ...otherArguments) {
        if (oldAction && !oldAction.call(this, e, ...otherArguments)) return
        window.location.href = typeof button.href === 'function' ? button.href() : button.href
      }
    }
  }

  // Action executed when row itself is clicked
  let defaultAction = applicableButtons.findIndex(button => button.isRowClickHandler)

  // Build action column
  const actionColumn = applicableButtons.length ? {
    title: 'Actions',
    data: null,
    render: (data, type, row, meta) => {
      // Render buttons and submenus
      const subMenusDone = new Set()
      const htmlButtons = applicableButtons.map(button => {
        if (button.subMenu) {
          if (!subMenusDone.has(button.subMenu)) {
            subMenusDone.add(button.subMenu)
            const subMenuHtmlButtons = subMenus[button.subMenu].map(button => {
              const disabled = typeof button.disabled === 'function' ? button.disabled(data) : button.disabled
              return `<li>
                <a
                  href="${disabled ? 'javascript:;' : encode((typeof button.href === 'function' ? button.href(data) : button.href) || 'javascript:;')}"
                  class="dt-action-btn ${button.className} ${disabled ? 'disabled' : ''}"
                  style="${button.isRowClickHandler ? 'font-weight: bold;' : ''}"
                  data-action-id="${button.index}"
                >
                  ${encode(button.text, false, true)}
                </a>
              </li>`.replace(/\s+/g, ' ') // Some browsers don't like the line breaks there
            })
            return `
              <div class="dt-action-btns-submenu btn-group">
                <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">
                  ${button.subMenu === true ? '' : encode(button.subMenu, false, true)}
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                  ${subMenuHtmlButtons.join('')}
                </ul>
              </div>
            `
          }
        } else {
          const disabled = typeof button.disabled === 'function' ? button.disabled(data) : button.disabled
          return `<a
            href="${disabled ? 'javascript:;' : encode((typeof button.href === 'function' ? button.href(data) : button.href) || 'javascript:;')}"
            class="dt-action-btn btn btn-sm ${button.className || 'btn-light'} ${disabled ? 'disabled' : ''}"
            style="${button.isRowClickHandler ? 'font-weight: bold;' : ''}"
            data-action-id="${button.index}"
            ${button.tooltip ? `title="${encode(button.tooltip)}" data-toggle="tooltip"` : ''}
          >
            ${encode(button.text, false, true)}
          </a>`.replace(/\s+/g, ' ') // Some browsers don't like the line breaks there
        }
      })
      return `<div class="dt-action-btns btn-group">${htmlButtons.join('')}</div>`
    },
    sortable: false,
    searchable: false
  } : null

  if (actionColumn) declaration.columns.push(actionColumn)

  // Wrap AJAX definition in handler
  if (declaration.serverSide) {
    if (typeof declaration.ajax === 'string') declaration.ajax = ajaxHandler($el, declaration.ajax, declaration.columns, declaration.customAjaxDataHook)
    if (!declaration.searchDelay) declaration.searchDelay = 750
  }
  delete declaration.customAjaxDataHook

  // Set selection style to "selection via API" because that's needed for button handling later
  declaration.select = { style: 'api' }

  // Default sorting (we don't want to mess around with indexes any longer, that's fragile)
  if (!declaration.order) declaration.order = []
  declaration.columns.forEach((column, i) => {
    if (column.defaultSort) declaration.order.push([i, column.defaultSort])
  })

  // Initialize actual DataTable
  dt = $el.DataTable(declaration)

  // Disable normal buttons which are supposed to be disabled
  normalButtons.forEach((button, index) => {
    // DataTables has a weird API there where we actually use a CSS selector to select the button
    // Problem: The normal buttons don#t have the data-action-id that we use for the improved buttons
    // So, we select by position instead (ugh)
    const disabled = typeof button.disabled === 'function' ? button.disabled() : button.disabled
    if (disabled) dt.button([`:nth-child(${index + 1})`]).disable()
  })

  // Workaround for DataTables bug where "visible" is not handled properly
  declaration.columns.forEach((column, i) => {
    if ('visible' in column) dt.column(i).visible(column.visible)
  })

  // Button handler
  $el.on('click', '.dt-action-btn', function (e) {
    if ($(this).hasClass('disabled')) return false
    const button = applicableButtons[$(this).attr('data-action-id')]
    const row = dt.row($(this).closest('tr'))
    try {
      if (!button.improved) row.select() // We build this on top of the buttons extension, so it should behave as if the row was selected
      if (button.action) {
        const res = button.action(e, dt, this, button, row) // The row argument is custom
        if (!res || !button.href) {
          e.preventDefault()
          return
        }
      }

      if (button.href && !e.originalEvent && e.isTrigger) {
        // Synthetic event, trigger link
        window.location.href = $(this).attr('href')
      }
    } finally {
      if (!button.improved) dt.rows().deselect()
    }
  })

  // Row click handler
  if (defaultAction > -1) {
    $el.addClass('clickable-rows') // Will show pointer cursor on the rows
    // Note: originally this said tr[role=row] but somehow the attribute is not set in some DT versions, apparently
    $el.on('click', 'tr', function (e) {
      if ($(e.target).closest('.dt-action-btns, a, button').length) return // Don't trigger if clicking a button/link in the row
      $(this).find(`.dt-action-btn[data-action-id="${defaultAction}"]`).click()
    })
  }

  return dt
}

module.exports = { setupImprovedDataTable }
