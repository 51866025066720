'use strict'

// ES6-style import/export is used here since Babel doesn't allow us to mix "async" and CommonJS-style export syntax
import LineGraph from './LineGraph'

const { getGraph } = require('../utils')
const moment = require('moment')
const Debouncer = require('../../Debouncer')

export default class CreditsUsedGraph extends LineGraph {
  constructor (graph, statsParams) {
    super(graph, statsParams)
    this.zoomDebouncer = new Debouncer(200) // We want the actions that we trigger on zoom fire only after 200ms of calmness
    this.visibleDateRange = { from: null, to: null }
  }

  onZoomChange () {
    this.updateVisibleDateRange()
    this.updateServiceUsageGraphZoom()
  }

  initEChartsInstance () {
    super.initEChartsInstance()

    // Update service usage chart timeframe on zoom
    // Note: jQuery-style .on('datazoom reset') doesn't work
    const handler = event => {
      this.zoomDebouncer.debounce(() => {
        this.onZoomChange()
      })
    }
    this.graph.on('datazoom', handler)
    this.graph.on('restore', handler)
  }

  render () {
    super.render()

    this.onZoomChange()
  }

  updateVisibleDateRange () {
    // Currently, startValue and endValue are not returned in event: https://stackoverflow.com/a/43910746/1871033
    const { startValue, endValue } = this.graph.getOption().dataZoom[0]

    // We currently have the date strings as values, so we have to turn them into timestamps here
    this.visibleDateRange.from = moment(this.graphData.date[startValue], 'MMM DD YYYY').valueOf()
    this.visibleDateRange.to = moment(this.graphData.date[endValue], 'MMM DD YYYY').valueOf()
  }

  updateServiceUsageGraphZoom () {
    const serviceUsageGraph = getGraph('serviceUsage')
    if (serviceUsageGraph) {
      Object.assign(serviceUsageGraph.viewParams, this.visibleDateRange)
      serviceUsageGraph.updateView()
    }
  }
}
