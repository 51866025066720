'use strict'

// Service list change handler for settings page

const $ = require('jquery')
const Debouncer = require('../Debouncer')
const { encode } = require('../utils')

module.exports = () => $(() => {
  let mainFormChanges = []
  const setupServiceListChangeHandler = ($field, $preview, $submitBtn, isMainForm) => {
    const $list = $preview.find('ul')
    const changeDebouncer = new Debouncer(isMainForm ? 1 : 500) // The main form doesn't really need debouncing, but it's easier to just use the same code

    $field.on('input change', e => {
      changeDebouncer.debounce(() => {
        $submitBtn.prop('disabled', true)
        $.post('/admin/settings/calcServiceListChanges', { newList: $field.val() }).done(result => {
          const changes = result
          if (isMainForm) mainFormChanges = changes

          $submitBtn.prop('disabled', false)

          $preview.toggle(!!result.length)

          $list.html('')

          for (const { type, value, newValue } of changes) {
            if (type === 'added') {
              $list.append(`<li><strong class="text-success">Add</strong> service "<strong>${encode(value)}</strong>"</li>`)
            } else if (type === 'deleted') {
              $list.append(`<li><strong class="text-danger">Delete</strong> service "<strong>${encode(value)}</strong>" and its templates and pricing</li>`)
            } else if (type === 'renamed') {
              $list.append(`<li><strong class="text-warning">Rename</strong> service "<strong>${encode(value)}</strong>" to "<strong>${encode(newValue)}</strong>"</li>`)
            }
          }
        }).fail(e => {
          console.error('Failed to check service list changes', e)
          window.alert('Error checking service list changes!')
        })
      })
    })
  }

  setupServiceListChangeHandler($('#usecases'), $('#usecases ~ .service-list-changes'), $('#submitBtn'), true)
  setupServiceListChangeHandler($('#usecases-modal .modal-field'), $('#usecases-modal .modal-field ~ .service-list-changes'), $('#usecases-modal .ok-btn'), true)

  $('#settingsForm').submit(() => {
    const deletions = mainFormChanges.filter(c => c.type === 'deleted')
    if (deletions.length) return window.confirm(`You are about to DELETE the service(s) ${deletions.map(c => `"${c.value}"`).join(', ')} and associated templates and pricing! Continue?`)
  })
})
