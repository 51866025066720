'use strict'

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = () => $(() => {
  const $dataField = $('[name=coinremitterCredentials]')
  const $table = $('#coinremitterCredentialsTable')

  let valueObject = {}
  try {
    valueObject = JSON.parse($dataField.val())
  } catch (e) {}

  for (const coin in valueObject) {
    for (const key in valueObject[coin]) {
      $table.find(`[data-coin=${encode(coin)}][data-key=${encode(key)}]`).val(valueObject[coin][key] || '')
    }
  }

  $table.on('input change', '[data-coin]', function () {
    const $this = $(this)
    const coin = $this.data('coin')
    const key = $this.data('key')
    const value = $this.val()

    if (!valueObject[coin]) valueObject[coin] = {}
    valueObject[coin][key] = value.trim()

    if (!Object.values(valueObject[coin]).some(x => x)) delete valueObject[coin]

    $dataField.val(JSON.stringify(valueObject))
  })

  $('#coinremitterCredentialsGroup').toggle($('#coinremitterEnabled').prop('checked'))
  $('#coinremitterEnabled').on('change', function () {
    $('#coinremitterCredentialsGroup').toggle($(this).prop('checked'))
  })
})
