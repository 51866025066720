'use strict'

// ES6-style import/export is used here since Babel doesn't allow us to mix "async" and CommonJS-style export syntax
import Graph from './Graph'

const moment = require('moment')
const echarts = require('echarts')
const { fillMissingDays } = require('../utils')

export default class ServiceBarGraph extends Graph {
  constructor (graph, statsParams) {
    super(graph, statsParams)
    this.viewParams = { from: moment().startOf('day').subtract(7, 'days').valueOf(), to: moment().startOf('day').valueOf() }
  }

  async loadData () {
    const rawData = await super.loadData()
    // Day filling is done here because it is later read out of rawData for KPI calculation
    // Therefore, it is logically part of rawData and not graphData, and hence it should be filled
    // in before rawData is even stored.
    fillMissingDays(rawData.data, this.fillDays, {})
    return rawData
  }

  transformData (rawData) {
    const graphData = { services: [], data: [] }
    const serviceData = {}

    // Not all services will have data at all days. Also, we may have services in the dataset that are no longer in
    // the currently active service list, yet they still have statistical value. Hence, we must gather a list
    // of all services existing in the dataset and make sure to fill in gaps. We will do that by dynamically adding
    // services to the list where required in order to avoid iterating over the dataset twice.

    let numberOfDays = 0
    Object.keys(this.rawData.data).forEach((timestamp, index) => {
      if (timestamp < this.viewParams.from || timestamp > this.viewParams.to) return

      numberOfDays++
      Object.keys(this.rawData.data[timestamp]).forEach(service => {
        const serviceName = window.services[service] || service

        if (!serviceData[serviceName]) {
          serviceData[serviceName] = []
          // Fill in previous days
          for (let i = 0; i < index; i++) serviceData[serviceName].push(0)
        }

        serviceData[serviceName].push(this.rawData.data[timestamp][service])
      })

      // Ensure all services have received a datapoint
      for (const serviceName in serviceData) {
        if (!serviceData[serviceName][index]) serviceData[serviceName][index] = 0
      }
    })

    // get average uses per service
    Object.keys(serviceData).forEach(key => {
      serviceData[key] = Math.round(serviceData[key].reduce((prev, curr) => prev + curr, 0) / (numberOfDays / 7))

      if (serviceData[key] === 0) delete serviceData[key] // We don't need all the zero rows at the end
    })

    // limit service name length and sort entries based on uses (dec.)
    graphData.services = Object.keys(serviceData).sort((a, b) => {
      return serviceData[a] - serviceData[b]
    }).map(name => {
      const maxNameLength = 25
      const regexp = new RegExp(`^(.{0,${maxNameLength}})\\s`)
      return name.length > maxNameLength ? ((name.match(regexp) || [])[1] || '') || name.substr(0, maxNameLength - 3) + '...' : name
    })
    graphData.data = Object.values(serviceData).sort((a, b) => a - b)

    // Save length of longest bar in order to calculate what bars would be too small to display labels
    graphData.longestBarLength = Math.max.apply(Math, graphData.data)

    return graphData
  }

  getChartConfig () {
    const chartConfig = super.getChartConfig()

    chartConfig.tooltip.axisPointer = { type: 'shadow' }

    return Object.assign(chartConfig, {
      title: {
        left: 'center', // yes, really
        text: this.name
      },
      color: '#fc4935',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        showTitle: true,
        right: '8%',
        feature: {
          restore: {title: 'reset'},
          saveAsImage: {title: 'download'}
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value'
        }
      ],
      yAxis: [
        {
          type: 'category',
          axisTick: {show: false},
          data: this.graphData.services,
          axisLabel: { formatter: `${this.prefix || ''}{value}${this.suffix || ''}` }
        }
      ],
      graphic: {
        type: 'text',
        left: 'center',
        top: 30,
        draggable: false,
        style: {
          text: 'To adjust this chart\'s timeframe, change the timeframe of the "Credits Used" chart above.',
          textAlign: 'center',
          fill: '#000',
          cursor: 'default'
        }
      },
      series: [{
        name: 'Usages/Week',
        type: 'bar',
        stack: 'services',
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0,
              [
                {offset: 0, color: '#f98144'},
                {offset: 0.5, color: '#f8f163'},
                {offset: 1, color: '#f8f163'}
              ]
            )
          },
          emphasis: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0,
              [
                {offset: 0, color: '#f98144'},
                {offset: 1, color: '#f8f163'}
              ]
            )
          }
        },
        label: {
          normal: {
            show: true,
            position: 'inside',
            color: '#000000',
            formatter: ({ value, dataIndex }) => {
              if (!value) return '' // Don't display zeroes
              if (!this.graphData.data[dataIndex]) return '' // May happen in a temporary state while data is updating during zooming of credits used chart
              const totalBarLength = this.graphData.data[dataIndex]
              if (totalBarLength < this.graphData.longestBarLength * 0.02) return '' // Don't show labels in bars that are <2% of max. width
              return value.toLocaleString()
            }
          }
        },
        data: this.graphData.data
      }]
    })
  }

  render () {
    // Resize box to content
    this.$box.css({ height: `${this.graphData.services.length * 1.2 + 10}rem` })
    if (this.graph) this.graph.resize()

    super.render()
  }
}
