'use strict'

// Lines table mixin
// Manages the table of lines in the admin panel

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, ellipsis, postNavigate } = require('../utils')

module.exports = ({ lineStatusColors }) => $(() => {
  setupImprovedDataTable($('#lineTable'), {
    columns: [
      { title: 'Line Name', data: 'lineName', className: 'break-word', render: (lineName, type, { type: lType, changeable, allowAlc, location }) => `<span style="${changeable ? 'color: limegreen;' : ''}">${encode(lineName)}</span> ${allowAlc ? '' : '<i title="Not an ALC line" class="fa fa-ban"></i>'}<br><small>${lType === 'voip' ? '<i title="VoIP line" class="fa fa-cloud"></i> VoIP' : encode(location)}</small>` },
      { title: 'Number', data: 'number', defaultContent: '', render: (number, type, { accCheckLastWasOk, accCheckLastError }) => `${encode(number)}${accCheckLastWasOk === 1 ? ' <i class="fa fa-circle text-success" title="Account OK"></i>' : ''}${accCheckLastWasOk === 0 ? ' <i class="fa fa-circle text-danger" title="Account Locked"></i>' : ''}${accCheckLastError ? ` <i class="fa fa-fa-exclamation-circle text-warning" title="Last account check failed: ${encode(accCheckLastError)}"></i>` : ''}` },
      {
        title: 'Status',
        data: 'augmentedStatus',
        defaultContent: '',
        render: (augmentedStatus, type, { error, generalError, changeStatus, nextScheduledChange, newNumber, scheduledSwitch }) => {
          const status = augmentedStatus.replace(/^.*?\//, '')
          return `<span style="color: ${lineStatusColors[status] || 'red'}">${encode(augmentedStatus)}</span>` +
            (changeStatus === 'pending' ? ` <i class="fa fa-refresh" title="New number waiting (${newNumber || 'UNKNOWN'})"></i>` : '') +
            (nextScheduledChange ? ` <i class="fa fa-clock-o" title="Change scheduled (${new Date(nextScheduledChange).toLocaleString()})"></i>` : '') +
            (scheduledSwitch ? ' <i class="fa fa-hourglass-half" style="color: black !important;" title="Switch-in scheduled"></i>' : '') +
            ((status !== 'Ready' && error) ? `<br><small title="${encode(error)}">${encode(ellipsis(error, 25))}</small>` : '') +
            (generalError ? `<br><small class="text-danger">${encode(generalError)}</span>` : '')
        }
      },
      { title: 'Pool', data: 'pool', render: $.fn.dataTable.render.text() },
      { title: 'Subscriptions', data: 'numberOfActiveSubscriptions', defaultContent: '', render: numberOfActiveSubscriptions => numberOfActiveSubscriptions.toLocaleString(), sortable: false },
      { title: 'Active Users', data: 'numberOfActiveUsers', defaultContent: '', render: numberOfActiveUsers => numberOfActiveUsers.toLocaleString(), sortable: false },
      { title: 'ID', data: 'id', defaultSort: 'asc' }
    ],
    createdRow: (row, data, dataIndex) => {
      if (!data.isActive) $(row).addClass('text-muted')
    },
    serverSide: true,
    ajax: '/admin-lines/dtAjax',
    customAjaxDataHook: data => {
      data.extraSearch = { hideNotInserted: $('#hide-not-inserted-sims-checkbox').prop('checked') }
    },
    buttons: [{
      text: 'Create New Line',
      href: '/admin/create-line',
      className: 'btn-primary'
    }, $.fn.dataTable.defaults.buttons, {
      text: '$pencil',
      tooltip: 'Edit',
      href: data => `/admin/edit-line/${data.id}`,
      className: 'btn-primary',
      extend: 'selected',
      isRowClickHandler: true
    }, {
      text: '$refresh',
      tooltip: 'Change Number',
      className: 'btn-dark',
      extend: 'selected',
      disabled: data => data.type === 'voip',
      action: (e, dt, node, button, row) => {
        const data = row.data()
        if (window.confirm(`This will terminate any subscriptions and change the line number! Continue?`)) {
          const $loader = $('#loader')
          $loader.fadeIn()
          postNavigate('/admin-lines/numberchange', { lineId: data.id })
        }
      }
    }, {
      text: '$power-off',
      tooltip: 'Trigger Modem Reset',
      href: data => `/admin/trigger-modem-reset/${data.id}`,
      className: 'btn-info',
      extend: 'selected',
      disabled: data => data.type === 'voip',
      action: (e, dt, node, button, row) => window.confirm('Are you sure?')
    }, {
      text: '$cog Agent line management page',
      tooltip: 'Agent line management page',
      href: data => `/agentLineManagement/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => !['agent', 'voip'].includes(data.type),
      subMenu: '$ellipsis-v'
    }, {
      text: '$database Gateway details',
      tooltip: 'Gateway details',
      href: data => `/admin/gateways/${data.gatewayUrl}/${data.gatewayPort}`,
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => !data.gatewayUrl || !data.gatewayPort,
      subMenu: '$ellipsis-v'
    }, {
      text: '$play Switch in ASAP',
      tooltip: 'Switch in ASAP',
      href: data => `/admin/gateways/${data.gatewayUrl}/${data.gatewayPort}/scheduleSwitchIn?returnToLines=1`,
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => !data.gatewayUrl || !data.gatewayPort || data.isActive || data.scheduledSwitch,
      subMenu: '$ellipsis-v'
    }, {
      text: '$hand-paper-o Cancel scheduled change',
      tooltip: 'Cancel scheduled change',
      href: data => `/admin/cancel-scheduled-change/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => !data.nextScheduledChange,
      action: (e, dt, node, button, row) => window.confirm('Are you sure?'),
      subMenu: '$ellipsis-v'
    }, {
      text: '$stop Cancel/reset countdown',
      tooltip: 'Cancel/reset countdown',
      href: data => `/admin/cancel-countdown/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => !data.softChangeCountdownTarget && !data.hardChangeCountdownTarget,
      subMenu: '$ellipsis-v'
    }, {
      text: '$chain-broken Terminate all subscriptions',
      tooltip: 'Terminate all subscriptions',
      href: data => `/admin/terminate-subscriptions/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      action: (e, dt, node, button, row) => window.confirm('Are you sure?'),
      subMenu: '$ellipsis-v'
    }, {
      text: '$pause-circle Prepare for activation',
      tooltip: 'Prepare for activation',
      href: data => `/admin/prepare-line-for-activation/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => data.generalError === 'waitingForActivation',
      action: (e, dt, node, button, row) => window.confirm('This will disable the module, set the waitingForActivation state, clear the number and SIM ID and zero out changes. Are you sure?'),
      subMenu: '$ellipsis-v'
    }, {
      text: '$comments-o View SMS',
      tooltip: 'View SMS',
      href: data => `/admin/view-line-sms/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      subMenu: '$ellipsis-v'
    }, {
      text: '$eraser Clear SMS',
      tooltip: 'Clear SMS',
      href: data => `/admin/clear-line-sms/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      action: (e, dt, node, button, row) => window.confirm('Are you sure?'),
      subMenu: '$ellipsis-v'
    }, {
      text: '$trash Delete',
      tooltip: 'Delete',
      href: data => `/admin/delete-line/${data.id}`,
      className: 'dropdown-item',
      extend: 'selected',
      action: (e, dt, node, button, row) => window.confirm('Are you sure?'),
      subMenu: '$ellipsis-v'
    }, {
      text: ' Hide Not-Inserted SIMs',
      className: 'hide-not-inserted-sims mb-0',
      tag: 'label',
      init: (dt, node, config) => {
        node.attr('for', 'hide-not-inserted-sims-checkbox')
        const $checkbox = $('<input>')
        $checkbox
          .attr('type', 'checkbox')
          .attr('id', 'hide-not-inserted-sims-checkbox')
          .css({'pointerEvents': 'none'})
          .prop('checked', true)
        node.prepend($checkbox)
      },
      action: (e, dt, node, config) => {
        $('#hide-not-inserted-sims-checkbox').prop('checked', !$('#hide-not-inserted-sims-checkbox').prop('checked'))
        dt.ajax.reload()
      }
    }]
  })
})
