var $ = require('jquery')
var {round2Up, customPaymentCalc, adjustOfferByMarkup, getUsdTopUpOffer} = require('../../../utils/customPaymentCalc')

$(() => {
  if (window.paymentPage) {
    $('.payment-method-nav .nav-link:first').click()

    var usdTopUpVal = new window.URLSearchParams(window.location.search).get('usdTopUp')
    var usdTopUpAmount = Number(usdTopUpVal) || 0
    var isUsdTopUp = !!usdTopUpVal

    var $stripePaymentButton = $('#stripe-continue-btn')
    var $cryptoPaymentButton = $('#_payment-button')
    var $webmoneyPaymentButton = $('#_webmoney-payment-button')
    var $paymentOnAccountPaymentButton = $('#paymentOnAccount-payment-button')
    var paypalAuthorized = false
    var isWebmoneyTransaction = false
    var isStripeTransaction = false
    var isPaymentOnAccountTransaction = false
    var stripe = (window.stripePublicKey && window.Stripe) ? window.Stripe(window.stripePublicKey) : null
    var stripePaymentMethod = null
    var stripeElements = null
    var stripeAutoFillInfo = null

    var minAmount = window.offers[1] ? window.offers[1].amount : 0

    var toggleButtons = function () {
      var offerId = $('input[name="offer"]:checked').val()
      var cryptoCoin = $('input[name="crypto"]:checked').val()
      var selectedOffer = window.offers[offerId] || window[offerId] || {}
      var validOffer = !!selectedOffer
      if (selectedOffer && (!Number.isInteger(selectedOffer.amount) || (selectedOffer.amount <= 0 && !isUsdTopUp) || selectedOffer.price <= 0)) validOffer = false
      $cryptoPaymentButton.prop('disabled', !((cryptoCoin || ($('.paymentGroup.active').data('pay') === 'paypal' && paypalAuthorized)) && validOffer))
      $webmoneyPaymentButton.prop('disabled', !validOffer)
      $stripePaymentButton.prop('disabled', !validOffer)
      $paymentOnAccountPaymentButton.prop('disabled', !validOffer)

      // This also updates the info in the payment on account section
      const newUsdBalance = window.userBalances.usdBalance - (selectedOffer.price || 0)
      if (newUsdBalance < 0 && !isUsdTopUp) {
        $('#overdrawn-amount').text('$' + (-newUsdBalance).toFixed(2))
        if (newUsdBalance < -window.userBalances.creditLine) {
          $('#line-of-credit').text('$' + window.userBalances.creditLine.toFixed(2))
          $('#manual-approval-notice').show()
        } else {
          $('#manual-approval-notice').hide()
        }
        $('#overdrawn-balance-notice').addClass('add-to-review').show()
      } else {
        $('#overdrawn-balance-notice').removeClass('add-to-review').hide()
      }
    }

    var continueStripeOrder = () => {
      $('#clientSideError').hide()

      isStripeTransaction = true

      if (!fillPaymentInfo()) {
        isStripeTransaction = false
        return
      }

      $('#stripe-loading').show()
      $('#stripe-payment-form').hide()

      $('#payment-form').hide()
      $('#stripe-payment-view').show()

      var stripeSavedCardId = $('[name=stripePaymentMethod]:checked').val() || null
      var payload = {
        amount: $('#selectedOfferAmount').val(),
        price: $('#selectedOfferPrice').val(),
        stripePaymentMethodId: stripeSavedCardId
      }

      $.ajax({
        url: '/stripe/transaction',
        method: 'POST',
        data: payload,
        dataType: 'json',
        success: (data) => {
          if (data.error) {
            $('#clientSideErrorMessage').text(data.error)
            $('#clientSideError').show()
            $(window).scrollTop(0)
            $('#stripe-loading').hide()
            $('#stripe-payment-form').show()
            $('#stripe-review-order').hide()
            return
          }

          console.log(data)

          if (stripeSavedCardId && stripeSavedCardId !== 'new') {
            stripePaymentMethod = 'card'
            stripeAutoFillInfo = { clientSecret: data.paymentIntentClientSecret, paymentMethodId: stripeSavedCardId }
            reviewStripeOrder()
            return
          }

          stripePaymentMethod = null
          stripeAutoFillInfo = null

          var elements = stripe.elements({ appearance: { theme: 'stripe' }, clientSecret: data.paymentIntentClientSecret })
          var paymentElement = elements.create('payment')
          paymentElement.on('change', event => {
            if (event.value && event.value.type) stripePaymentMethod = event.value.type
            if (typeof event.complete === 'boolean') $('#stripe-review-order').prop('disabled', !event.complete)
            if (event.error) {
              $('#stripe-review-order').prop('disabled', false)
              $('#stripe-error-message').text(event.error.message)
            } else if (event.complete) {
              $('#stripe-error-message').text('')
            }
          })
          paymentElement.on('ready', event => {
            $('#stripe-loading').hide()
            $('#stripe-payment-form').show()
          })
          paymentElement.mount('#stripe-payment-element')

          stripeElements = elements
        },
        error: () => {
          $('#clientSideErrorMessage').text('an unexpected error occurred. Please try again.')
          $('#clientSideError').show()
          $(window).scrollTop(0)
          $('#stripe-loading').hide()
          $('#stripe-payment-form').show()
          $('#stripe-review-order').hide()
        }
      })
    }
    $stripePaymentButton.click(continueStripeOrder)

    var reviewStripeOrder = e => {
      if (e) e.preventDefault()
      $('#clientSideError').hide()

      if (!fillPaymentInfo()) return

      $('#payment-complete-button').show()
      $('#stripe-payment-view').hide()
      $('#payment-review').show()
    }
    $('#stripe-review-order').click(reviewStripeOrder)

    var confirmStripePayment = () => {
      var returnUrl = new window.URL('/stripe/transaction/result', window.location.href)
      var promise = stripeAutoFillInfo
        ? stripe.confirmCardPayment(stripeAutoFillInfo.clientSecret, { payment_method: stripeAutoFillInfo.paymentMethodId, return_url: returnUrl.href })
        : stripe.confirmPayment({ elements: stripeElements, confirmParams: { return_url: returnUrl.href } })
      promise.then(result => {
        if (result.error) {
          if (result.error.code === 'payment_intent_unexpected_state') {
            console.error('Stripe payment failed with unexpected state error - refreshing page')
            window.location.reload(true)
            return
          }

          $('#clientSideErrorMessage').text(result.error.message)
          $('#clientSideError').show()
          $(window).scrollTop(0)
          $('#payment-loading').hide()
          if (stripeAutoFillInfo) {
            stripeAutoFillInfo = null
            isStripeTransaction = false
            $('#payment-form').show()
          } else {
            $('#stripe-payment-view').show()
          }
          return
        }

        console.log('Stripe payment confirmed, should redirect now...')

        if (stripeAutoFillInfo && !result.paymentIntent.next_action) {
          returnUrl.searchParams.set('payment_intent', result.paymentIntent.id)
          returnUrl.searchParams.set('payment_intent_client_secret', stripeAutoFillInfo.clientSecret)

          window.location.href = returnUrl.href
        }
      }).catch(e => {
        console.error(e)
        $('#clientSideErrorMessage').text('an unexpected error occurred. Please try again.')
        $('#clientSideError').show()
        $(window).scrollTop(0)
        $('#payment-loading').hide()
        if (stripeAutoFillInfo) {
          stripeAutoFillInfo = null
          isStripeTransaction = false
          $('#payment-form').show()
        } else {
          $('#stripe-payment-view').show()
        }
      })
    }

    $('.delete-stripe-payment-method').click(function () {
      var paymentMethodId = $(this).data('id')
      if (window.confirm('Are you sure you want to delete the saved credit card ' + $(this).closest('label').find('span').text() + '?')) {
        $(this).hide()
        $stripePaymentButton.prop('disabled', true)
        $.ajax({
          url: '/stripe/payment-method/' + paymentMethodId,
          method: 'DELETE'
        }).done(() => {
          $(this).closest('.form-group').remove()
          if (!$('[name=stripePaymentMethod]:checked').length) $('[name=stripePaymentMethod]:first').click()
        }).fail(() => {
          window.alert('Failed to delete payment method!')
          $(this).show()
        }).always(() => {
          toggleButtons() // Restore state
        })
      }
    })

    var reviewCryptoOrder = () => {
      $cryptoPaymentButton.prop('disabled', true)

      toggleButtons() // Restore state
      var $checkedCryptoRadioButton = $('input[name="crypto"]:checked')
      var selectedCryptoCoin = $('.paymentGroup.active').data('pay') === 'crypto' ? $checkedCryptoRadioButton.val() : undefined

      if (selectedCryptoCoin || $('.paymentGroup.active').data('pay') === 'paypal') {
        if (!fillPaymentInfo()) return

        $('#payment-complete-button').show()
        $('#payment-form').hide()
        $('#payment-review').show()

        $('.refund-address-box').toggle($checkedCryptoRadioButton.attr('data-needs-refund-address') === '1')
        $('.refund-tag-box').toggle($checkedCryptoRadioButton.attr('data-needs-refund-tag') === '1')
        $('.crypto-coin-symbol').text(selectedCryptoCoin)

        var lastRefundAddress = (window.localStorage[`refundAddress:${window.loggedInUser.id}:${selectedCryptoCoin}`] || '').split(';')
        $('#refund-address').val($checkedCryptoRadioButton.attr('data-needs-refund-address') === '1' ? lastRefundAddress[0] || '' : '')
        $('#refund-tag').val($checkedCryptoRadioButton.attr('data-needs-refund-tag') === '1' ? lastRefundAddress[1] || '' : '')
      } else {
        $('#clientSideErrorMessage').text('No valid payment method selected.')
        $('#clientSideError').show()
        $(window).scrollTop(0)
      }
    }
    $cryptoPaymentButton.click(reviewCryptoOrder)

    var reviewWebmoneyOrder = function () {
      $webmoneyPaymentButton.prop('disabled', true)

      toggleButtons() // Restore state

      isWebmoneyTransaction = true

      if (!fillPaymentInfo()) {
        isWebmoneyTransaction = false
        return
      }

      $('#payment-complete-button').show()
      $('#payment-form').hide()
      $('#webmoneyInfo').show()
      $('#payment-review').show()
    }
    $webmoneyPaymentButton.click(reviewWebmoneyOrder)

    var reviewPaymentOnAccountOrder = function () {
      $paymentOnAccountPaymentButton.prop('disabled', true)

      toggleButtons() // Restore state

      isPaymentOnAccountTransaction = true

      if (!fillPaymentInfo()) {
        isPaymentOnAccountTransaction = false
        return
      }

      $('#payment-complete-button').show()
      $('#payment-form').hide()
      $('#paymentOnAccountReviewInfo').html($('#overdrawn-balance-notice').html()).toggle($('#overdrawn-balance-notice').hasClass('add-to-review'))
      $('#payment-review').show()
    }
    $paymentOnAccountPaymentButton.click(reviewPaymentOnAccountOrder)

    $('#payment-complete-button').click(() => {
      $('#payment-review').hide()
      $('#payment-loading').show()
      var paymentOption = $('#paymentOption').val()

      if (paymentOption === 'PayPal') {
        $('#selectedOffer').submit()
      } else if (paymentOption === 'WebMoney') {
        var payload = {
          amount: $('#selectedOfferAmount').val(),
          price: $('#selectedOfferPrice').val()
        }

        $.ajax({
          url: '/webmoney/transaction',
          method: 'POST',
          data: payload,
          dataType: 'json',
          success: (data) => {
            if (data.error) {
              $('#clientSideErrorMessage').text(data.error)
              $('#clientSideError').show()
              $(window).scrollTop(0)
              return
            }

            console.log(data)
            $('#webmoney-amount').val(payload.price)
            $('#webmoney-desc').val(data.txId)
            $('#webmoney-payment-form-button').click()
          },
          error: () => {
            $('#clientSideErrorMessage').text('an unexpected error occurred. Please try again.')
            $('#clientSideError').show()
            $(window).scrollTop(0)
          }
        })
      } else if (paymentOption === 'Stripe') {
        confirmStripePayment()
      } else if (paymentOption === 'Account') {
        $('#paymentOnAccountAmount').val($('#selectedOfferAmount').val())
        $('#paymentOnAccountPrice').val($('#selectedOfferPrice').val())
        $('#paymentOnAccount-payment-form').submit()
      } else {
        handleCryptoPayment()
      }
    })

    $('.payment-back-button').click(() => {
      $('.refund-address-box').hide()

      if (isStripeTransaction && !$('#stripe-payment-view').is(':visible') && !stripeAutoFillInfo) {
        $('#stripe-payment-view').show()
        $('#payment-review').hide()
        return
      }

      var selectedCryptoCoin = $('input[name="crypto"]:checked').val()
      if (!selectedCryptoCoin) { // For non-crypto, we should reset the whole state by reloading the page
        var selectedOffer = window.offers[$('input[name="offer"]:checked').val()] || window[$('input[name="offer"]:checked').val()]
        // Passing the amount here ensures that we can reselect the original amount
        window.location.replace('?amount=' + selectedOffer.amount + (isUsdTopUp ? '&usdTopUp=' + selectedOffer.price : ''))
        return
      }

      $('#payment-review').hide()
      $('#webmoneyInfo').hide()
      $('#cryptoPaymentInfo').hide()
      $('#paymentOnAccountReviewInfo').hide()
      $('#payment-form').show()
    })

    var checkCustom = function () {
      $('#customOffer').prop('checked', true).click()
    }

    if ($('#paypal-button-container')[0] && window.paypal && window.paypal.Button) {
      window.paypal.Button.render({
        env: window.paypalSandbox ? 'sandbox' : 'production',
        // Show the buyer a 'Pay Now' button in the checkout flow
        commit: true,
        // payment() is called when the button is clicked
        payment: function () {
          // Set up a url on your server to create the payment
          var CREATE_URL = '/topup/paypal/create'
          if (!fillPaymentInfo()) return

          var payload = {
            amount: $('#selectedOfferAmount').val(),
            price: $('#selectedOfferPrice').val(),
            singlePrice: $('#selectedOfferSinglePrice').val(),
            fullString: $('#selectedOfferFullString').val(),
            percent: $('#selectedOfferPercent').val()
          }

          // !(x > y) also catches a non-numeric x (unlike x <= y)
          if (!(payload.amount > 0) && !isUsdTopUp) {
            window.alert('Please enter a valid amount!')
            return
          }

          // Make a call to your server to set up the payment
          return window.paypal.request.post(CREATE_URL, payload)
            .then(function (res) {
              if (res.error) {
                $('#clientSideErrorMessage').text(res.error)
                $('#clientSideError').show()
                $(window).scrollTop(0)
                return
              }

              $('#transactionId').val(res.transactions[0].custom)
              console.log(res)
              return res.id
            })
        },

        // onAuthorize() is called when the buyer approves the payment
        onAuthorize: function (data, actions) {
          // Set up the data you need to pass to your server
          paypalAuthorized = true

          $('#paymentId').val(data.paymentID)
          $('#payerId').val(data.payerID)
          toggleButtons()
          reviewCryptoOrder()
        }
      }, '#paypal-button-container')
      // END
    }
    // Important: If we wouldn't initialize this here, window.customOffers would
    // implicity be document.getElementById('customOffer'), which would further down
    // where window.offers[...] || window[...] is used (which is a bad idea, by the way)
    // give a false positive because the DOM element will be returned which is truthy
    // but not what we expect.
    window.customOffer = customPaymentCalc(0, window.offers)

    $('#customAmount').on('input change keydown', function () {
      var amount = Number($('#customAmount').val() || 0)
      var badAmount = false
      if (!Number.isInteger(amount) || amount < minAmount) {
        badAmount = true
      } else {
        badAmount = false
        $('#clientSideError').hide()
      }
      window.customOffer = customPaymentCalc(amount >= minAmount ? amount : 0, window.offers)
      $('#customPrice').text(badAmount ? '—' : round2Up(window.customOffer.price).toFixed(2))
      $('#customSinglePrice').text(badAmount ? '—' : round2Up(window.customOffer.singlePrice).toFixed(2))
      $('#customPercent').text(window.customOffer.percent)
      $('#customPercentContainer').toggle(window.customOffer.percent >= 1)
      window.customOffer.price ? $('#customBuyInfo').show() : $('#customBuyInfo').hide()
      checkCustom()
      toggleButtons()
    })

    $('#usdTopUpAmount').on('input change keydown', function () {
      var amount = Number($('#usdTopUpAmount').val() || 0)
      if (!Number.isInteger(amount) || amount <= 0) {
        // Bad amount
      } else {
        $('#clientSideError').hide()
      }
      window.customOffer = getUsdTopUpOffer(amount)
      $('#customBuyInfo').hide()
      checkCustom()
      toggleButtons()
    })
  }

  $('#customAmount').click(checkCustom)
  $('.errorButton').click(function () {
    $('#clientSideError').hide()
  })

  $('input[name="offer"]').on('change click', () => {
    var selectedOffer = window.offers[$('input[name="offer"]:checked').val()] || window[$('input[name="offer"]:checked').val()]
    if (Number.isInteger(selectedOffer.amount) && selectedOffer.amount > 0) {
      $('#paypal-button-container').show()
    } else {
      $('#paypal-button-container').hide()
    }
    $('#clientSideError').hide()
    toggleButtons()
  })

  $('input[name="crypto"]').click(() => {
    $('#clientSideError').hide()
    toggleButtons()
  })

  function fillPaymentInfo () {
    var paypal = $('.paymentGroup.active').data('pay') === 'paypal' ? 'PayPal' : null
    var selectedCryptoCoin = $('input[name="crypto"]:checked').val() || null

    var selectedOffer = window.offers[$('input[name="offer"]:checked').val()] || window[$('input[name="offer"]:checked').val()]
    if (selectedOffer.amount <= 1 && !isUsdTopUp && selectedCryptoCoin) {
      $('#clientSideErrorMessage').text('Does not meet minimum purchase requirement. Please increase order or select alternate payment method.')
      $('#clientSideError').show()
      $(window).scrollTop(0)
      return false
    }

    if (selectedOffer) {
      var paymentMethodForMarkup = null
      if (paypal) {
        $('#review-payment-method').text(`PayPal`)
        paymentMethodForMarkup = 'PayPal'
      } else if (isWebmoneyTransaction) {
        $('#review-payment-method').text('WebMoney')
        paymentMethodForMarkup = 'WebMoney'
      } else if (isStripeTransaction) {
        paymentMethodForMarkup = 'Stripe'
        var stripePaymentMethodNames = {
          apple_pay: 'Apple Pay',
          acss_debit: 'ACSS Debit',
          afterpay_clearpay: 'AfterPay/ClearPay',
          alipay: 'AliPay',
          au_becs_debit: 'AU BECS Debit',
          bacs_debit: 'BACS Debit',
          bancontact: 'BanContact',
          boleto: 'Boleto',
          card: 'Credit Card',
          eps: 'EPS',
          fpx: 'FPX',
          giropay: 'Giropay',
          google_pay: 'Google Pay',
          grabpay: 'GrabPay',
          ideal: 'iDEAL',
          klarna: 'Klarna',
          oxxo: 'OXXO',
          p24: 'P24',
          sepa_debit: 'SEPA Debit',
          sofort: 'SOFORT',
          wechat_pay: 'WeChat Pay'
        }
        $('#review-payment-method').text((stripePaymentMethodNames[stripePaymentMethod] || 'Stripe') + (stripeAutoFillInfo ? ` (Saved card: ${$('[for="spm-' + stripeAutoFillInfo.paymentMethodId + '"]').text().trim()})` : ''))
      } else if (selectedCryptoCoin) {
        $('#review-payment-method').text($(`label[for="${selectedCryptoCoin}"]`).text() || selectedCryptoCoin)
        $('#cryptoPaymentInfo').show()
        paymentMethodForMarkup = selectedCryptoCoin
      } else if (isPaymentOnAccountTransaction) {
        if (isUsdTopUp) {
          const message = $('#paymentOnAccountMessage').val()
          $('#review-payment-method').text('Manual' + (message ? ` (${message})` : ''))
        } else {
          $('#review-payment-method').text('Payment on Account')
        }
        paymentMethodForMarkup = 'Account'
      } else {
        $('#clientsideErrorMessage').text('A payment error occurred')
        $('#clientsideError').show()
        $(window).scrollTop(0)
        return false
      }

      var markup = window.paymentMethodMarkup[paymentMethodForMarkup] || 0
      selectedOffer = adjustOfferByMarkup(selectedOffer, markup)

      $('#selectedOfferAmount').val(selectedOffer.amount)
      $('#selectedOfferPrice').val(selectedOffer.price)
      $('#selectedOfferSinglePrice').val(selectedOffer.singlePrice)
      $('#selectedOfferPercent').val(selectedOffer.percent)
      $('#selectedOfferFullString').val(selectedOffer.fullString)
      $('#paymentOption').val(selectedCryptoCoin || paypal || (isWebmoneyTransaction && 'WebMoney') || (isStripeTransaction && 'Stripe') || (isPaymentOnAccountTransaction && 'Account'))

      $('#review-credits').text(selectedOffer.amount)
      $('#review-price').text(round2Up(selectedOffer.price).toFixed(2))
      if (markup > 0) {
        $('#review-markup').text('(incl. ' + markup + '% processing fee)')
      } else if (markup < 0) {
        $('#review-markup').text('(incl. ' + (-markup) + '% discount)')
      } else {
        $('#review-markup').text('')
      }
    } else {
      $('#clientsideErrorMessage').text('Please select an Offer.')
      $('#clientsideError').show()
      $(window).scrollTop(0)
      return false
    }

    return true
  }

  function handleCryptoPayment () {
    if ($('input[name="crypto"]:checked').attr('data-needs-refund-address') === '1' && !$('#refund-address').val().trim()) {
      window.alert('Please specify a refund address!')

      $('#payment-review').show()
      $('#payment-loading').hide()

      return
    }

    var payload = {
      amount: $('#selectedOfferAmount').val(),
      coin: $('#paymentOption').val(),
      price: $('#selectedOfferPrice').val(),
      refundAddress: $('#refund-address').val().trim(),
      refundTag: $('#refund-tag').val().trim()
    }

    // !(x > y) also catches a non-numeric x (unlike x <= y)
    if (!(payload.amount > 0) && !isUsdTopUp) {
      window.alert('Please enter a valid amount!')

      $('#payment-review').show()
      $('#payment-loading').hide()

      return
    }

    $.ajax({
      url: '/CryptoTransaction/create',
      method: 'POST',
      data: payload,
      dataType: 'json',
      success: (data) => {
        if (data.error) {
          $('#clientSideErrorMessage').text(data.error)
          $('#clientSideError').show()
          $(window).scrollTop(0)
          $('#payment-crypto').hide()
          $('#payment-loading').hide()
          $('#payment-form').show()
          return
        }

        $('.has-status').toggle(!!data.status_url)
        $('.btc-segwit-warning').toggle(data.nonCp && payload.coin === 'BTC')
        $('#cryptoPaymentErrorMessage').text('REMINDER: Due to the volatile nature of crypto-currencies, credits will be calculated based on value at the time of confirmation. Payment MUST be submitted within ONE (1) HOUR or your order will not be processed.')
        if (data.status_url) {
          $('#cryptoStatusLink').attr('href', data.status_url)
          $('#cryptoStatusLink').text(data.status_url)
        }
        $('#cryptoQrCode').after(`<img src="${data.qrcode_url}" alt="${data.qrcode_url}">`)
        $('#cryptoPaymentDetails').html(`Please send <strong>${data.amount} ${$(`label[for="${payload.coin}"]`).text() || payload.coin}</strong> to address <strong>${data.address}</strong>` + (data.dest_tag ? ` with payment ID <strong>${data.dest_tag}</strong>` : ''))
        $('#payment-loading').hide()
        $('#payment-crypto').show()

        if (payload.refundAddress) {
          window.localStorage.setItem(`refundAddress:${window.loggedInUser.id}:${payload.coin}`, payload.refundAddress + (payload.refundTag ? ';' + payload.refundTag : ''))
        } else {
          window.localStorage.removeItem(`refundAddress:${window.loggedInUser.id}:${payload.coin}`)
        }
      },
      error: () => {
        $('#payment-crypto').hide()
        $('#payment-loading').hide()
        $('#payment-form').show()
        $('#clientSideErrorMessage').text('An unexpected error occurred. Please try again.')
        $('#clientSideError').show()
        $(window).scrollTop(0)
      }
    })
  }

  function updateSelectedAmount () {
    const offerId = $('input[name="offer"]:checked').val()
    $('.payment-credits-table tr').removeClass('selected')
    $('.payment-credits-table tr[data-offer-id="' + offerId + '"]').addClass('selected')
  }

  updateSelectedAmount()
  $('input[name="offer"]').on('change click', updateSelectedAmount)

  // Set amount again in case it was a page reload with previous amount saved
  var prevAmount = Number(new window.URL(window.location).searchParams.get('amount'))
  if (prevAmount) {
    if (prevAmount < minAmount) prevAmount = minAmount

    var offerIndex = window.offers.findIndex(o => o.amount === prevAmount)
    if (offerIndex > -1 && prevAmount !== 1) { // Remember: The 1-credit-offer is hidden on Gold
      $('#offer' + offerIndex).prop('checked', true).click()
    } else {
      $('#customOffer').prop('checked', true).click()
      $('#customAmount').val(prevAmount).change()
    }
  }

  if (usdTopUpAmount) {
    $('#usdTopUpAmount').val((Number(usdTopUpAmount) || 0).toFixed(2))
    $('#usdTopUpAmount').change()
  }
})
