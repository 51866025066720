const $ = require('jquery')

$(() => {
  if (window.loggedInUser) {
    const ping = () => {
      if (window.ioConnection) {
        window.ioConnection.emit('ping')
      } else {
        $.post({
          url: '/ping'
        }).done(response => {
          if (typeof response === 'string' && response.match(/<form[^>]*action="\/login"/i)) {
            // Seems like we got redirected to the login page in the request - so let's reload to reflect that
            window.location.href = window.location.href
          }
        })
      }
    }

    setInterval(ping, 75000)
  }
})
