const $ = require('jquery')
let ioClient = require('socket.io-client')
if (ioClient.default && !ioClient.connect) ioClient = ioClient.default // Fix weird bundling mess with default export of this module - sometimes it worked, sometimes it didn't

window.ioConnection = null

$(() => {
  if (window.loggedInUser && window.wsAuthToken !== '_disabled') {
    const io = ioClient.connect({ transports: ['websocket'] })

    let tokenRefreshedOnce = false
    io.on('connect', () => { // We don't want socket.io's polling transport, that would be counter-productive - we hvae our own fallback
      io.emit('authenticate', { token: window.wsAuthToken })
    })

    io.on('authTokenUpdate', ({ token }) => {
      window.wsAuthToken = token
    })

    io.on('authenticationResult', ({ authenticated }) => {
      if (!authenticated) {
        if (tokenRefreshedOnce) {
          console.error('Failed to authenticate websocket connection')
          window.ioConnection = null
        } else {
          tokenRefreshedOnce = true
          $.getJSON('/wsAuthToken').done(({ token }) => {
            window.wsAuthToken = token
            io.emit('authenticate', { token })
          }).fail(() => {
            console.error('Failed to fetch new WS auth token')
            window.ioConnection = null
          })
        }
      } else {
        window.ioConnection = io
        tokenRefreshedOnce = false
      }
    })

    io.on('statusUpdate', data => {
      if (window.onStatusUpdate) window.onStatusUpdate(data)
    })

    io.on('disconnect', () => {
      window.ioConnection = null
    })
  }
})
