'use strict'

const $ = require('jquery')
const { encode } = require('./utils')

$(() => {
  if ($('#sms-table').length) {
    // wsConnection.js will also call this when a statusUpdate message is coming in through websockets
    window.onStatusUpdate = data => {
      lastUpdate = Date.now()

      if (data.reload || String($('.phone-number').data('phone-number')) !== data.number || String($('.phone-number').data('service')) !== data.currentService) {
        if (!$('#loader').is(':visible')) { // A reload in the middle of number assignment can put the user on a page where they don't have a line
          window.location.href = window.location.href
        }
      } else {
        $('.line-countdown').data('expiration', data.lineExpiration ? new Date(data.lineExpiration).valueOf() : '')

        $('#status').text(data.status)
        if (data.status === 'Pending') {
          $('.pending-info').show()
        } else {
          $('.pending-info').hide()
        }
        if (data.status === 'Error' || data.status === 'Disconnected') {
          $('.error-info').show()
        } else {
          $('.error-info').hide()
        }

        // For the moment, we don't need to concern ourselves with deletions of single messages
        // But we don't want to replace the table every time because it will clear text selection
        if (!data.sms.length || !$(`#sms-table [data-id="${data.sms[0].id}"]`).length || !$(`#sms-table [data-id="${data.sms[data.sms.length - 1].id}"]`).length) {
          const $tbody = $('#sms-table tbody')
          $tbody.empty()
          for (const sms of data.sms) {
            const $tr = $(`<tr data-id="${sms.id}"/>`).appendTo($tbody)
            $('<td/>').text(new Date(sms.timestamp).toLocaleString()).appendTo($tr)
            $('<td/>').text(sms.phoneNumber).appendTo($tr)
            $('<td/>').html(encode(sms.text)).appendTo($tr)
          }
          $('.no-sms').toggle(data.sms.length < 1)
          $('.view-more-sms').toggle(data.sms.length >= 10)
          $('.status-connecting').toggle(!data.ready)
          $('.status-connecting .connecting-duration').text(data.connectingDuration)
          $('.status-connected').toggle(data.ready)
        }
      }
    }

    let lastUpdate = 0
    setInterval(() => {
      if (window.ioConnection) {
        if (lastUpdate > Date.now() - 60000) return
        window.ioConnection.emit('requestStatusUpdate')
      } else {
        $.getJSON('/indexAjax').done(window.onStatusUpdate)
      }
      lastUpdate = Date.now()
    }, 15000)
  }
})
