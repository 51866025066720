'use strict'

const $ = require('jquery')

module.exports = () => $(() => {
  $('h2').each((_, el) => {
    const children = []
    let child = $(el).next()
    while (child.length && !['H2', 'BR'].includes(child.prop('tagName'))) {
      children.push(child[0])
      child = child.next()
    }

    $(el).append($('<i class="fa fa-caret-down fa-fw" />').click(self => {
      if (!$(self.target).hasClass('rotate-180')) {
        $(children).slideUp('fast')
        $(self.target).addClass('rotate-180')
      } else {
        $(children).slideDown('fast')
        $(self.target).removeClass('rotate-180')
      }
    }))
  })
})
