'use strict'

const $ = require('jquery')

module.exports = ({ underlyingField, modal, previewFn = s => s }) => $(() => {
  const $underlyingField = $(underlyingField)
  const $modal = $(modal)
  const $modalField = $modal.find('.modal-field')

  const $container = $(`
    <p class="form-control mb-0">
      <span></span>
      <button type="button" class="btn btn-sm btn-outline-primary" style="padding: 0 6px; vertical-align: baseline;">
        <i class="fa fa-pencil"></i> Edit
      </button>
    </p>
  `)
  const $preview = $container.find('span')
  $underlyingField.hide().after($container)

  const updatePreview = () => $preview.text(previewFn($underlyingField.val()))
  updatePreview()
  $underlyingField.on('change', updatePreview)

  $container.find('button').on('click', () => {
    $modalField.val($underlyingField.val())
    $modal.modal('show')
  })

  $modal.find('.ok-btn').on('click', () => {
    $modal.modal('hide')
    $underlyingField.val($modalField.val()).change()
  })

  $modal.on('shown.bs.modal', () => $modalField.focus())

  // Suppress enter (input)/ctrl+enter (textarea) and turn it into OK click (otherwise it would submit the outer form)
  $modalField.on('keydown', e => {
    if (e.keyCode === 13 && (e.ctrlKey || e.target.tagName === 'INPUT')) {
      e.preventDefault()
      $modal.find('.ok-btn').click()
    }
  })
})
