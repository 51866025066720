'use strict'

const $ = require('jquery')

$(function () {
  function deleteTemplate (tplType, id) {
    $(`#${tplType}Template-${id}`).parent().remove()
  }

  $('.deleteNumberTemplate').click(function (evt) {
    deleteTemplate('number', $(this).data('idx'))
  })

  $('.deleteTextTemplate').click(function (evt) {
    deleteTemplate('text', $(this).data('idx'))
  })

  $('.addNumberTemplate').click(function (evt) {
    addTemplate.call(this, 'number', evt)
  })

  $('.addTextTemplate').click(function (evt) {
    addTemplate.call(this, 'text', evt)
  })

  $('#templateTestText').keydown(function (evt) {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      $('#templateTestBtn').click()
    }
  })

  $('#templateTestBtn').click(function (evt) {
    $('#templateTestText, [name="textTemplates[]"]').removeClass('text-success').removeClass('text-danger').removeClass('text-warning')

    const text = $('#templateTestText').val()
    if (!text) return

    const templates = Object.entries(Array.from($('[name="textTemplates[]"]').get()).map(el => el.value)).filter(x => x)

    let overallMatch = !templates.length
    for (const [i, tpl] of templates) {
      try {
        const match = !!text.match(new RegExp(tpl, 'i'))
        if (match) overallMatch = true
        $(`#textTemplate-${i}`).addClass(match ? 'text-success' : 'text-danger')
      } catch (e) {
        $(`#textTemplate-${i}`).addClass('text-warning')
      }
    }

    $('#templateTestText').addClass(overallMatch ? 'text-success' : 'text-danger')
  })

  function addTemplate (tplType, evt) {
    const tplTypeTitleCase = tplType[0].toUpperCase() + tplType.substr(1)
    $(`.${tplType}-empty`).hide()

    const $buttonContainer = $(this).parent()

    // take the last button and increase the id. we're not submitting this id anyway
    let newFieldId = $(`.delete${tplTypeTitleCase}Template`).last().data('idx') * 1
    newFieldId = isNaN(newFieldId) ? 0 : newFieldId + 1
    const $newField = $('<div/>', {
      'class': 'input-group mb-3'
    })
    const $newButton = $('<button/>', {
      'class': `btn btn-danger delete${tplTypeTitleCase}Template`,
      type: 'button',
      text: '-',
      'data-idx': newFieldId
    })

    const $newButtonContainer = $('<div/>', {
      'class': 'input-group-append'
    }).append($newButton)

    $newField.append($('<input/>', {
      'class': 'form-control',
      id: `${tplType}Template-${newFieldId}`,
      type: 'text',
      name: `${tplType}Templates[]`
    })).append($newButtonContainer)

    $buttonContainer.before($newField)

    $newButton.click(function (evt) {
      console.log(this)
      deleteTemplate(tplType, $(this).data('idx'))
      if (!$(`[name="${tplType}Templates[]"]`).length) $(`.${tplType}-empty`).show()
    })
  }
})
