'use strict'

// USD account table mixin
// Manages the table of transactions

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode } = require('../utils')

// hasUnread: whether user has unread messages
// canCompose: whether user can compose new messages
// canSendOnlyToAdmin: whether user can send messages only to admin
module.exports = ({ hasUnread, canCompose, canSendOnlyToAdmin }) => $(() => {
  const dt = setupImprovedDataTable($('#messages-table'), {
    autoWidth: false,
    columns: [
      { title: 'Time', data: 'timestamp', className: 'dt-single-line', defaultSort: 'desc', render: $.fn.dataTable.render.date(), width: '20%' },
      { title: 'From', data: 'sender', className: 'dt-single-line', render: $.fn.dataTable.render.text(), width: '20%' },
      { title: 'To', data: 'recipient', className: 'dt-single-line', render: $.fn.dataTable.render.text(), width: '20%' },
      { title: 'Contents', data: 'text', className: 'dt-single-line', render: (text, type, { subject }) => `${subject ? `<strong>${encode(subject)}</strong> - ` : ''}${encode(text)}`, width: '60%', sortable: false }
    ],
    createdRow: (row, data, dataIndex) => {
      // Highlight unread messages
      if (data.unread) $(row).addClass('alert-warning')
    },
    serverSide: true,
    ajax: `/messages/dtAjax`,
    customAjaxDataHook: data => {
      data.extraSearch = { sent: $('#nav-sent-tab').hasClass('active') }
    },
    buttons: [$.fn.dataTable.defaults.buttons, ...canCompose ? [{
      text: canSendOnlyToAdmin ? 'Send Message to Support' : 'Compose',
      href: '/messages/compose',
      className: 'btn-primary'
    }] : [], ...hasUnread ? [{
      text: 'Mark All as Read',
      href: '/messages/markAllAsRead',
      className: 'btn-success'
    }] : []]
  })

  function updateColumns () {
    const isSent = $('#nav-sent-tab').hasClass('active')
    dt.column(1).visible(!isSent)
    dt.column(2).visible(isSent)
    dt.columns.adjust().draw()
  }

  updateColumns()
  $('a[data-toggle="tab"]').on('shown.bs.tab', () => {
    updateColumns()
    dt.ajax.reload()
  })

  $('#messages-table').addClass('clickable-rows')
  $('#messages-table').on('click', 'tr', function () {
    const data = dt.row(this).data()
    if (data) window.location = `/messages/${data.id}`
  })
})
