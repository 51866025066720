'use strict'

// TMO account lock confirmation mixin
// Allows to show a big confirmation box in regards to manually marking line as locked

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = () => $(() => {
  $(document).on('click', '.mark-account-locked', e => {
    const $link = $(e.target).closest('.mark-account-locked')
    const $modal = $(`
      <div class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Report locked account for line ${encode($link.attr('data-line-id'))}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <span style="font-size: 125%;">
                <p>You are about to report a provider account as locked. This will trigger an automatic response which has an impact on users.</p>
                <p><strong>Only</strong> continue if you received an error message that looks as follows (with the exact wording "<em><strong>We’ve temporarily locked your account to protect your information.</strong></em>"):</p>
                <img src="/img/tmoLocked.png" style="border: 1px solid grey;" />
                <p>Are you sure?</p>
              </span>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger submit-btn">Yes, mark LOCKED</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">No, cancel</button>
            </div>
          </div>
        </div>
      </div>
    `).appendTo('body')

    $modal.on('hidden.bs.modal', () => {
      $modal.remove()
    })

    $modal.find('.submit-btn').click(() => {
      $modal.find('button').prop('disabled', true)
      window.location.href = $link.attr('data-href')
    })

    $modal.modal('show')
  })
})
