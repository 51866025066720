'use strict'

const $ = require('jquery')
const { normalizeOffers, addDiscountPercentToOffers } = require('../../../../utils/customPaymentCalc')
const { encode } = require('../utils')

module.exports = ({ allowEmpty = false }) => $(() => {
  // Pricing settings
  const offers = normalizeOffers($('#pricing').val())
  const buildPricingRow = ({amount = '', singlePrice = ''} = {}) => `
    <tr>
      <td><input class="amount-field form-control" value="${encode(amount)}"/></td>
      <td><input class="singlePrice-field form-control" value="${encode(singlePrice)}"/></td>
      <td><input class="price-field form-control text-right" value="" readonly/></td>
      <td>
        <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info"><i class="fa fa-plus"></i></button>
        <button type="button" title="Remove" class="remove-btn btn btn-outline-danger"><i class="fa fa-minus"></i></button>
      </td>
    </tr>
  `

  const $pricingEditor = $(`
    <div class="pricing-editor clearfix">
      <table class="table">
        <thead>
          <tr>
            <th>Number of credits</th>
            <th>Price per credit</th>
            <th>Total price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          ${offers.map(buildPricingRow).join('')}
        </tbody>
      </table>
      <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info"><i class="fa fa-plus"></i> Add row</button>
    </div>
  `).insertAfter('#pricing')

  // Use a .on(..., 'selector') instead of a normal listener on the elements because buttons can
  // be generated on the fly
  $pricingEditor.on('click', '.add-btn', (e) => {
    const $row = $(e.target).closest('tr')
    if ($row.length) {
      $row.after(buildPricingRow())
    } else {
      $pricingEditor.find('tbody').append(buildPricingRow())
    }
    updateTable()
    return false
  })

  $pricingEditor.on('click', '.remove-btn', (e) => {
    $(e.target).closest('tr').remove()
    updateTable()
    return false
  })

  const getPricingDataFromTable = () => {
    return Array.from($pricingEditor.find('tbody tr').map((_, el) => {
      const amount = Number($(el).find('.amount-field').val())
      const singlePrice = Number($(el).find('.singlePrice-field').val())
      return amount ? `${amount}=${singlePrice}` : ''
    })).filter(x => x).join('\n')
  }

  const updateTable = () => {
    $pricingEditor.find('.amount-field').prop('readonly', false)
    $pricingEditor.find('tr:first-child .amount-field').prop('readonly', true).val(1)

    // This will disallow non-flat curves for Gold
    $pricingEditor.find('tr:first-child .singlePrice-field').prop('readonly', false)
    if (!allowEmpty) $pricingEditor.find('tr:first-child .remove-btn').prop('disabled', true)
    $pricingEditor.find('tr:not(:first-child) .singlePrice-field').prop('readonly', true).val($('tr:first-child .singlePrice-field').val())

    const pricingData = getPricingDataFromTable()
    $('#pricing').val(pricingData)
    const offers = addDiscountPercentToOffers(normalizeOffers(pricingData))

    $pricingEditor.find('tbody tr').each((_, el) => {
      const amount = Number($(el).find('.amount-field').val())
      const singlePrice = Number($(el).find('.singlePrice-field').val())
      const offer = offers.find(o => o.amount === amount && o.singlePrice === singlePrice)
      if (offer) {
        $(el).find('.price-field').val('$' + Number(offer.price).toFixed(2))
      } else {
        $(el).find('.price-field').val('')
      }
    })
  }

  updateTable()
  $pricingEditor.on('input change', 'input', updateTable)
})
