'use strict'

const $ = require('jquery')

function setKpiValues ({ kpi, today, yesterday, sameDayPrevWeek, prefix, suffix }) {
  // Gracefully handle nulls
  today = Number(today) || 0
  yesterday = Number(yesterday) || 0
  sameDayPrevWeek = Number(sameDayPrevWeek) || 0

  const $block = $(`.kpi-block[data-kpi="${kpi}"]`)

  const formatVal = val => (prefix || '') + val.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + (suffix || '')

  $block.find('.kpi-value').text(formatVal(today))

  const setComparisonValue = (comparison, value) => {
    const percent = ((today / value) - 1) * 100 || 0 // The `|| 0` converts NaN coming from 0/0 to 0%
    const percentStr = percent.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + ' %' // toLocaleString also renders Infinity as ∞, just as we want it
    const $subBlock = $block.find(`[data-comparison="${comparison}"]`)

    if (percent > 0) {
      $subBlock.find('.kpi-comparison-ratio').html(`<span class="text-success"><i class="fa fa-arrow-up fa-fw"></i> ${percentStr}</span>`)
    } else if (percent < 0) {
      $subBlock.find('.kpi-comparison-ratio').html(`<span class="text-danger"><i class="fa fa-arrow-down fa-fw"></i> ${percentStr}</span>`)
    } else {
      $subBlock.find('.kpi-comparison-ratio').html(`<span><i class="fa fa-arrow-right fa-fw"></i> ${percentStr}</span>`)
    }

    $subBlock.find('.kpi-comparison-value').text(formatVal(value))
  }

  setComparisonValue('yesterday', yesterday)
  setComparisonValue('sameDayPrevWeek', sameDayPrevWeek)

  const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  $block.find('[data-comparison=sameDayPrevWeek] .weekday').text(WEEKDAYS[new Date().getDay()])
}

module.exports = { setKpiValues }
