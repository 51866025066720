'use strict'

// Gateway details mixin

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = () => $(() => {
  $('form.ajax-form').each(function () {
    const $form = $(this)
    $form.submit(() => {
      const formData = $form.serialize() // Must be done before disabling
      $form.find('input, textarea, select, button').prop('disabled', true)
      $form.find('.alert').hide()
      $form.find('.ajax-loader').show()

      $.post($form.attr('action'), formData).done(result => {
        const msg = result.response
        $form.find('.alert-success').html('<strong>Request successful!</strong>' + (msg ? ` Response:<br><pre style="white-space: pre-wrap;">${encode(msg)}</pre>` : '')).show()
      }).fail(jqXhr => {
        const msg = jqXhr.responseJSON && jqXhr.responseJSON.error
        $form.find('.alert-danger').html('<strong>Request failed!</strong>' + (msg ? ` Response:<br><pre style="white-space: pre-wrap;">${encode(msg)}</pre>` : '')).show()
      }).always(() => {
        $form.find('input, textarea, select, button').prop('disabled', false)
        $form.find('.ajax-loader').hide()
      })

      return false
    })
  })
})
