'use strict'

const $ = require('jquery')
const moment = require('moment')

function setViewState (state) {
  $('#graphs-loader').toggle(state !== 'ready')
  $('#graphs').toggle(state === 'ready')
  if (state === 'error') $('#clientSideErrorMessage').text('An error has occurred while loading the statistics data. Retrying...')
  if (state !== 'loading') $('#clientSideError').toggle(state === 'error')
  $('#domainId').prop('disabled', state !== 'ready').selectpicker('refresh')
}

// I know this is slightly ugly and not optimized, but we need to fill missing days if this leads to bugs please report to mike@glitch.tech
function fillMissingDays (dataObject, fillMethod = 'fixed', fillData = 0) {
  let fill = fillData
  let date = moment(Math.min.apply(Math, Object.keys(dataObject)))
  const maxDate = moment((new Date()).setHours(0, 0, 0, 0)) // fill until today!

  while (date < maxDate) {
    switch (fillMethod) {
      case 'last':
        // fill matches the previous date if the "filling" attribute is "last"
        fill = dataObject[Object.keys(dataObject).find(x => moment(Number(x)).format('MMM DD YYYY') === date.format('MMM DD YYYY'))]
        break
    }
    date = date.add(1, 'days')
    if (!Object.keys(dataObject).find(x => moment(Number(x)).format('MMM DD YYYY') === date.format('MMM DD YYYY'))) {
      dataObject[date.format('x')] = fill
    }
  }
}

function getGraph (id) {
  return window.graphs.find(graph => graph.id === id)
}

module.exports = { fillMissingDays, setViewState, getGraph }
