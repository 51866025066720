const $ = require('jquery')

$(() => {
  let interval
  const countdown = () => {
    const timestamp = Number($('.line-countdown').data('expiration'))
    if (!timestamp) {
      return
    }

    const remainingTime = Math.max(0, timestamp - new Date())
    const minutes = Math.floor(remainingTime / 60000)
    const seconds = Math.floor(remainingTime / 1000) % 60
    $('.line-countdown').text(minutes + ':' + seconds.toString().padStart(2, '0'))

    $('.line-countdown-wrapper').toggle(remainingTime <= 120000)
    if (remainingTime <= 0) {
      $('.nav-phone-number').hide()
      if ($('.line-countdown[data-reload-on-zero]').length) {
        window.location.href = window.location.href
      }
      clearInterval(interval)
    }
  }
  countdown()
  interval = setInterval(countdown, 1000)
})
