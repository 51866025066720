'use strict'

// Service Cost Preview Table

const $ = require('jquery')
const { encode } = require('../utils')
const { getUsecaseIdFromDisplayName } = require('../../../../utils/usecasesTools')

const getDurationLabel = (days) => (days % 7 === 0) ? `${days / 7} week${Number(days) === 7 ? '' : 's'}` : `${days} day${Number(days) === 1 ? '' : 's'}`

module.exports = ({ isSettingsPage = false } = {}) => $(() => {
  $(document).on('click', '.show-service-cost-table', e => {
    const usecases = isSettingsPage
      ? Object.fromEntries($('[name=usecases]').val().split('\n').map(x => x.trim()).filter(x => x).map(x => [getUsecaseIdFromDisplayName(x), x]))
      : window.usecases
    const globalSharedDurations = isSettingsPage
      ? Object.fromEntries($('[name=rentalPricing]').val().split(',').map(x => x.trim()).filter(x => x).map(x => [x, { days: Number(x), label: getDurationLabel(x) }]))
      : window.sharedDurations
    const globalPrivateDurations = isSettingsPage
      ? Object.fromEntries($('[name=privateRentalPricing]').val().split('\n').map(x => x.trim()).filter(x => x).map(x => x.split('=').map(y => y.trim())).map(([days, credits]) => [days, { days: Number(days), label: getDurationLabel(days), credits: Number(credits) }]))
      : window.privateDurations
    const globalServiceTierOptions = isSettingsPage
      ? JSON.parse($('[name=serviceTierOptions]').val())
      : window.globalServiceTierOptions
    const globalDefaultServiceTier = isSettingsPage
      ? JSON.parse($('[name=defaultServiceTier]').val())
      : window.globalDefaultServiceTier
    const globalServicePricing = isSettingsPage
      ? JSON.parse($('[name=serviceTiers]').val())
      : window.globalServicePricing
    const basicServiceMargins = isSettingsPage
      ? {}
      : JSON.parse($('[name=basicServiceMargins]').val())
    const privateMargin = isSettingsPage
      ? {}
      : JSON.parse($('[name=privateMargin]').val())
    const defaultPrice = isSettingsPage
      ? null
      : JSON.parse($('[name=defaultPrice]').val())
    const privatePricing = isSettingsPage
      ? null
      : JSON.parse($('[name=privatePricing]').val() || '{}') // This does not exist yet
    const serviceTierOptions = isSettingsPage
      ? {}
      : JSON.parse($('[name=serviceTierOptions]').val())
    const servicePricing = isSettingsPage
      ? {}
      : JSON.parse($('[name=serviceTiers]').val())

    const allDurations = Object.values({ ...globalSharedDurations, ...globalPrivateDurations }).sort((a, b) => a.days - b.days).map(({ days, label }) => ({ days, label }))

    const data = Object.entries(usecases).map(([usecase, displayName]) => {
      const systemTierId = globalServicePricing[usecase] || globalDefaultServiceTier.id
      const systemTierData = globalServiceTierOptions[systemTierId || ''] || globalDefaultServiceTier

      const values = {
        usecase,
        displayName,
        systemTierName: systemTierData.name,
        durations: allDurations.map(({ days }) => ({
          days,
          basePrice: systemTierData[days] || null,
          markup: systemTierData[days] ? 0 : null,
          originalMarkup: undefined,
          fallback: null
        })),
        tierName: systemTierData.name,
        isSystemTier: true
      }

      for (const duration of values.durations) {
        if (!duration.basePrice) continue

        duration.markup = ((basicServiceMargins[systemTierId || systemTierData.name]) || {})[duration.days] || 0
      }

      if (defaultPrice && values.durations.some(duration => duration.basePrice && defaultPrice[duration.days] != null)) {
        values.tierName = 'Custom Default'
        values.isSystemTier = false

        for (const duration of values.durations) {
          if (!duration.basePrice) continue

          duration.fallback = null
          if (!defaultPrice[duration.days]) {
            duration.fallback = 'systemTier'
          } else if (defaultPrice.preventNegative && defaultPrice[duration.days] < duration.basePrice) {
            duration.originalMarkup = defaultPrice[duration.days] - duration.basePrice
            duration.markup = 0
          } else {
            duration.markup = defaultPrice[duration.days] - duration.basePrice
          }
        }
      }

      if (servicePricing[usecase]) {
        const wasSystemTier = values.isSystemTier

        const serviceTierId = servicePricing[usecase]
        const serviceTierData = serviceTierOptions[serviceTierId]
        values.tierName = serviceTierData.name
        values.isSystemTier = false

        for (const duration of values.durations) {
          if (!duration.basePrice) continue

          duration.fallback = null
          if (!serviceTierData[duration.days]) {
            duration.fallback = wasSystemTier ? 'systemTier' : 'customDefault'
          } else if (serviceTierData.preventNegative && serviceTierData[duration.days] < duration.basePrice) {
            duration.originalMarkup = serviceTierData[duration.days] - duration.basePrice
            duration.markup = 0
          } else {
            duration.markup = serviceTierData[duration.days] - systemTierData[duration.days]
          }
        }
      }

      for (const duration of values.durations) {
        duration.totalPrice = duration.basePrice ? duration.basePrice + duration.markup : null
      }

      return values
    })

    // Special case for _PRIVATE
    const privateValues = {
      usecase: '_PRIVATE',
      displayName: '(Private Rental)',
      systemTierName: 'Private',
      durations: allDurations.map(({ days }) => ({
        days,
        basePrice: (globalPrivateDurations[days] && globalPrivateDurations[days].credits) || null,
        markup: (globalPrivateDurations[days] && globalPrivateDurations[days].credits) ? 0 : null,
        originalMarkup: undefined,
        fallback: null
      })),
      tierName: 'Private',
      isSystemTier: true
    }

    for (const duration of privateValues.durations) {
      if (!duration.basePrice) continue

      duration.markup = (privateMargin || {})[duration.days] || 0
    }

    if (privatePricing && privateValues.durations.some(duration => duration.basePrice && privatePricing[duration.days] != null)) {
      privateValues.tierName = 'Custom Private'
      privateValues.isSystemTier = false

      for (const duration of privateValues.durations) {
        if (!duration.basePrice) continue

        duration.fallback = null
        if (!privatePricing[duration.days]) {
          duration.fallback = 'systemTier'
        } else if (privatePricing.preventNegative && privatePricing[duration.days] < duration.basePrice) {
          duration.originalMarkup = privatePricing[duration.days] - duration.basePrice
          duration.markup = 0
        } else {
          duration.markup = privatePricing[duration.days] - duration.basePrice
        }
      }
    }

    for (const duration of privateValues.durations) {
      duration.totalPrice = duration.basePrice ? duration.basePrice + duration.markup : null
    }

    data.unshift(privateValues)

    const getWarningIcon = duration => {
      if (duration.fallback === 'customDefault') {
        return '<i class="text-warning fa fa-exclamation-triangle" title="Price missing, using custom default"></i> '
      } else if (duration.fallback === 'systemTier') {
        return '<i class="text-danger fa fa-exclamation-triangle" title="Price missing, using system default"></i> '
      }
      return ''
    }

    const $modal = $(`
      <div class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Preview Service Cost List</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table table-striped" style="font-size: 75%;">
                <thead>
                  <tr>
                    <th width="280px;">Service</th>
                    <th width="280px;">Tier</th>
                    ${allDurations.map(duration => `
                      <th width="140px;" align="right">${encode(duration.label)}</th>
                      <th width="100px;" align="right" data-visible="false">Base Cost</th>
                      <th width="140px;" align="right" data-visible="false">Markup</th>
                      <th width="140px;" align="right" data-visible="false">Total Service Cost</th>
                    `).join('')}
                  </tr>
                </thead>
                <tbody>
                  ${data.map(values => `
                    <tr>
                      <td>${encode(values.displayName)}</td>
                      <td>
                        <i class="fa fa-fw fa-${values.isSystemTier ? 'star' : 'user'}" title="${values.isSystemTier ? 'System Tier' : 'Custom Tier'}"></i>
                        ${encode(values.tierName)}${values.isSystemTier ? '' : ` <small class="text-muted">(instead of <i class="fa fa-star" title="System Tier"></i> ${encode(values.systemTierName)})</small>`}
                      </td>
                      ${values.durations.map(duration => duration.basePrice === null ? '<td></td><td></td><td></td><td></td>' : `
                        <td align="right" data-order="${duration.totalPrice.toFixed(2)}" style="padding: 0.25em 0.75em; line-height: 1;">
                        ${duration.originalMarkup ? '<i class="text-info fa fa-shield" title="Negative markup prevented"></i> ' : ''}
                        ${getWarningIcon(duration)}
                          <i class="fa fa-phone-square"></i> ${duration.totalPrice.toFixed(2)}<br>
                          <small ${duration.markup < 0 ? 'class="text-danger"' : duration.markup > 0 ? 'class="text-success"' : ''}>Markup: ${duration.markup.toFixed(2)}</small>
                        </td>
                        <td align="right" data-order="${duration.basePrice}">
                          <i class="fa fa-phone-square"></i> ${duration.basePrice.toFixed(2)}
                        </td>
                        <td align="right" ${duration.markup < 0 ? 'class="text-danger"' : duration.markup > 0 ? 'class="text-success"' : ''} data-order="${duration.markup.toFixed(2)}">
                          <i class="fa fa-phone-square"></i> ${duration.originalMarkup !== undefined ? ` <span class="text-muted strikethrough">${duration.originalMarkup.toFixed(2)}</span> ` : ''}${duration.markup.toFixed(2)}
                        </td>
                        <td align="right" data-order="${duration.totalPrice.toFixed(2)}">
                          ${getWarningIcon(duration)}
                          <i class="fa fa-phone-square"></i> ${duration.originalMarkup !== undefined ? ` <span class="text-muted strikethrough">${(duration.basePrice + duration.originalMarkup).toFixed(2)}</span> ` : ''}${duration.totalPrice.toFixed(2)}
                        </td>
                      `).join('')}
                    </tr>
                  `).join('')}
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    `).appendTo('body')

    const $table = $modal.find('table')

    const dt = $table.DataTable({
      autoWidth: false,
      order: [[0, 'asc']] // Service
    })

    const $durationSelectWrapper = $(`
      <label style="position: relative; top: -2px; margin-right: 10px;">
        <select class="custom-select custom-select-sm form-control form-control-sm">
          <option value="overview" selected>Durations overview</option>
          ${allDurations.map((duration, i) => `
            <option value="${i}">Details for ${encode(duration.label)}</option>
          `).join('')}
        </select>
      </label>
    `).prependTo($table.parent().find('.dataTables_filter'))

    const $durationSelect = $durationSelectWrapper.find('select')
    $durationSelect.change(function () {
      const val = this.value
      for (let i = 0; i < allDurations.length; i++) {
        dt.column(2 + i * 4).visible(val === 'overview') // Overview column
        dt.column(2 + i * 4 + 1).visible(Number(val) === i) // Base cost column
        dt.column(2 + i * 4 + 2).visible(Number(val) === i) // Markup column
        dt.column(2 + i * 4 + 3).visible(Number(val) === i) // Total cost column
      }
      dt.columns.adjust().draw()
    })

    $modal.on('hidden.bs.modal', () => {
      $table.DataTable().destroy(true)
      $modal.remove()
    })

    $modal.modal('show')
  })
})
