'use strict'

// USD account table mixin
// Manages the table of transactions

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, ellipsis } = require('../utils')

// userId: ID of the user whose transactions should be shown
// isGlobalPending: if true then all users are shown but only pending transactions
module.exports = ({ userId, isGlobalPending }) => $(() => {
  if (isGlobalPending) userId = 'globalPending'

  const usdAmountRenderer = (effectiveUsdAccAmount, type, { confirmed }) => {
    let usdAccColor = 'black'

    if (!confirmed) {
      usdAccColor = 'orange'
    } else {
      if (effectiveUsdAccAmount > 0) {
        usdAccColor = 'limegreen'
      } else if (effectiveUsdAccAmount < 0) {
        usdAccColor = 'red'
      }
    }

    let html = `<span style="color: ${usdAccColor};">$${effectiveUsdAccAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>`
    return html
  }

  setupImprovedDataTable($('#transaction-table'), {
    columns: [
      { title: 'Time', data: 'timestamp', defaultSort: 'desc', render: (timestamp, type, { ip }) => `${$.fn.dataTable.render.date()(timestamp, type)}${ip ? `<br><small style="display: inline-block; word-break: break-word;">${encode(ip)}</small>` : ''}` },
      ...(isGlobalPending) ? [
        { title: 'User', data: 'associatedUsername', defaultContent: '', render: (associatedUsername, type, { userId }) => userId ? `<a href="/usdAccount/${userId}" title="${encode(associatedUsername)}">${encode(ellipsis(associatedUsername, 10))}</a>` : '', sortable: false }
      ] : [],
      { title: 'ID', data: 'txId', className: 'break-word', render: $.fn.dataTable.render.text() },
      { title: 'Description', data: 'description', className: 'break-word', render: description => description }, // Not HTML-encoded!
      { title: 'Status', data: 'status', render: status => `<span style="color: ${({pending: 'orange', failed: 'red'})[status] || 'limegreen'};">${encode(status || 'success')}</span>`, sortable: false },
      { title: 'USD Amount', data: 'effectiveUsdAccAmount', defaultContent: '0.00', className: 'text-right', render: usdAmountRenderer }
    ],
    serverSide: true,
    ajax: `/usdAccount/${userId}/dtAjax`,
    buttons: [$.fn.dataTable.defaults.buttons, ...window.loggedInUser.isAdmin ? [{
      text: '$check',
      tooltip: 'Approve',
      href: data => `/usdAccount/${userId}/setApproval/${data.id}/approve`,
      className: 'btn-success',
      extend: 'selected',
      disabled: data => data.status !== 'pending'
    }, {
      text: '$times',
      tooltip: 'Decline',
      href: data => `/usdAccount/${userId}/setApproval/${data.id}/decline`,
      className: 'btn-warning',
      extend: 'selected',
      disabled: data => data.status !== 'pending'
    }, {
      text: '$trash',
      tooltip: 'Refund & Delete',
      href: data => `/usdAccount/${userId}/delete/${data.id}`,
      className: 'btn-danger',
      extend: 'selected',
      action: (e, dt, node, button, row) => window.confirm('Are you sure?')
    }] : [{
      text: '$times',
      tooltip: 'Cancel',
      href: data => `/usdAccount/${userId}/cancel/${data.id}`,
      className: 'btn-warning',
      extend: 'selected',
      disabled: data => data.status !== 'pending'
    }]]
  })
})
