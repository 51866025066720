'use strict'

// Transactions table mixin
// Manages the table of transactions

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, ellipsis } = require('../utils')
const { customPaymentCalc } = require('../../../../utils/customPaymentCalc')

const moment = require('moment')
require('moment-duration-format') // mutates moment

// userId: ID of the user whose transactions should be shown
// showUser: Whether the user column should be shown (if requester was admin or subaccount-owner/reseller)
// showResellerActions: Whether actions such as modifying payment status should be shown (if requester was admin or reseller)
// isGlobal: if true then all users are shown
// showAdjustmentTxFilter: if true then the filter for hiding adjustment transactions is shown
module.exports = ({ userId, showUser, showResellerActions, offers, isGlobal, showAdjustmentTxFilter }) => $(() => {
  if (isGlobal) userId = 'global'

  const amountRenderer = (effectiveAmount, type, { confirmed, effectiveUsdValue, effectiveUsdAccAmount }) => {
    let color = 'black'
    let usdColor = 'black'
    let usdAccColor = 'black'

    if (!confirmed) {
      color = 'orange'
      usdColor = 'orange'
      usdAccColor = 'orange'
    } else {
      if (effectiveUsdAccAmount > 0) {
        usdAccColor = 'limegreen'
      } else if (effectiveUsdAccAmount < 0) {
        usdAccColor = 'red'
      }

      if (effectiveUsdValue > 0) {
        usdColor = 'limegreen'
      } else if (effectiveUsdValue < 0) {
        usdColor = 'red'
      }

      if (effectiveAmount > 0) {
        color = 'limegreen'
      } else if (effectiveAmount < 0) {
        color = 'red'
      }
    }

    let html = `<span style="color: ${color};"><i class="fa fa-phone-square"></i> ${effectiveAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>`
    if (effectiveUsdValue) html += `<br><small style="color: ${usdColor};">$${effectiveUsdValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</small>`
    if (effectiveUsdAccAmount) html += `<br><small>[<em><strong>Acc:</strong> <span style="color: ${usdAccColor};">$${effectiveUsdAccAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></em>]</small>`
    return html
  }

  setupImprovedDataTable($('#transaction-table'), {
    columns: [
      { title: 'Time', data: 'timestamp', defaultSort: 'desc', render: (timestamp, type, { ip }) => `${$.fn.dataTable.render.date()(timestamp, type)}${ip ? `<br><small style="display: inline-block; word-break: break-word;">${encode(ip)}</small>` : ''}` },
      ...(isGlobal) ? [
        { title: 'User', data: 'associatedUsername', defaultContent: '', render: (associatedUsername, type, { userId }) => userId ? `<a href="/transactions/${userId}" title="${encode(associatedUsername)}">${encode(ellipsis(associatedUsername, 10))}</a>` : '', sortable: false }
      ] : [],
      ...(showUser || isGlobal) ? [
        { title: 'Recipient', data: 'recipient', defaultContent: '', render: (recipient, type, { recipientId }) => recipient ? `<a href="/transactions/${recipientId}" title="${encode(recipient)}">${encode(ellipsis(recipient, 10))}</a>` : '', sortable: !isGlobal }
      ] : [],
      { title: 'ID', data: 'txId', className: 'break-word', render: $.fn.dataTable.render.text() },
      { title: 'Description', data: 'description', className: 'break-word', render: (description, type, { clearsAt }) => description + (clearsAt ? '<br /><small><i class="fa fa-exclamation-triangle"></i> This transaction will be credited in <strong>' + moment.duration(moment().diff(moment(clearsAt))).humanize() + '</strong></small>' : '') }, // Not HTML-encoded!
      { title: 'Status', data: 'status', render: status => `<span style="color: ${({pending: 'orange', failed: 'red'})[status] || 'limegreen'};">${encode(status || 'success')}</span>`, sortable: false },
      { title: 'Amount', data: 'effectiveAmount', defaultContent: '0.00', className: 'text-right', render: amountRenderer }
    ],
    serverSide: true,
    ajax: `/transactions/${userId}/dtAjax`,
    customAjaxDataHook: data => {
      data.extraSearch = { hideAdjustments: $('#hide-adjustment-tx-checkbox').prop('checked') }
    },
    buttons: [$.fn.dataTable.defaults.buttons, ...window.loggedInUser.isAdmin ? [...isGlobal ? [] : [{
      text: '$dollar',
      tooltip: 'Edit sale value',
      className: 'btn-info',
      extend: 'selected',
      disabled: data => !['manualTransaction', 'sale'].includes(data.type) || data.effectiveUsdAccAmount,
      action: (e, dt, node, button, row) => {
        const data = row.data()

        // Note: To avoid confusion, the amount is displayed as absolute value and the $ value correspondingly normalized
        // The $ value would then be negative only if an actual "refund"-type sale was intended (from creator to target),
        // regardless from which user's point of view the admin is looking at the transaction.
        // This was done to avoid further confusion that would come from the fact that something would always be backwards,
        // either the $ amount's sign displayed in the table vs. in the edit field upon editing, or the $ amount's sign
        // displayed in the edit field upon editing vs. upon creating. That's because it is normal and expected that the
        // $ amount's sign in the table is always the opposite of what was entered upon creation (because under normal
        // non-refund circumstances, the user will receive credits (+) while paying money (-)).

        const $modal = $('.edit-sale-value-modal')
        $modal.find('form').attr('action', `/transactions/${userId}/editSaleValue/${data.id}`)
        $modal.find('.amount').text(Math.abs(data.effectiveAmount))
        $modal.find('.description').html(data.description)

        if (data.type === 'sale') {
          $modal.find('[name=usdValue]').val((data.effectiveUsdValue * -(Math.sign(data.effectiveAmount) || 1)).toFixed(2))
          $modal.find('[type=submit]').removeClass('btn-success').addClass('btn-primary').text('Save')
        } else {
          const usdValue = isGlobal ? 0 : customPaymentCalc(Math.abs(data.effectiveAmount), offers).price
          $modal.find('[name=usdValue]').val(usdValue.toFixed(2))
          $modal.find('[type=submit]').removeClass('btn-primary').addClass('btn-success').text('Convert to Sale & Save')
        }

        $modal.modal('show')
      }
    }], {
      text: '$check',
      tooltip: 'Clear immediately',
      href: data => `/transactions/${userId}/clearNow/${data.id}`,
      className: 'btn-success',
      disabled: data => !data.clearsAt,
      extend: 'selected'
    }, {
      text: '$trash',
      tooltip: 'Refund & Delete',
      href: data => `/transactions/${userId}/delete/${data.id}`,
      className: 'btn-danger',
      extend: 'selected',
      action: (e, dt, node, button, row) => window.confirm('Are you sure?')
    }] : [], ...showResellerActions ? [{
      text: '$money',
      tooltip: 'Update Payment Status',
      className: 'btn-info',
      extend: 'selected',
      disabled: data => !data.canUpdatePaymentStatus,
      action: (e, dt, node, button, row) => {
        const data = row.data()

        const $modal = $('.update-payment-status-modal')
        $modal.find('form').attr('action', `/transactions/${userId}/updatePaymentStatus/${data.id}`)
        $modal.find('.amount').text(Math.abs(data.effectiveAmount))
        $modal.find('.description').html(data.description)

        $modal.find('[name=status]').prop('checked', false)
        $modal.find('button[type=submit]').prop('disabled', true)
        $modal.find('[name=comment]').val('')

        $modal.modal('show')
      }
    }] : [], ...showAdjustmentTxFilter ? [{
      text: ' Hide Adjustment TXs',
      className: 'hide-adjustment-tx mb-0',
      tag: 'label',
      init: (dt, node, config) => {
        node.attr('for', 'hide-adjustment-tx-checkbox')
        const $checkbox = $('<input>')
        $checkbox
          .attr('type', 'checkbox')
          .attr('id', 'hide-adjustment-tx-checkbox')
          .css({'pointerEvents': 'none'})
        node.prepend($checkbox)
      },
      action: (e, dt, node, config) => {
        $('#hide-adjustment-tx-checkbox').prop('checked', !$('#hide-adjustment-tx-checkbox').prop('checked'))
        dt.ajax.reload()
      }
    }] : []]
  })

  $('.update-payment-status-modal [name=status]').on('change', function () {
    if (this.checked) $('.update-payment-status-modal button[type=submit]').prop('disabled', false)
  })

  if (window.location.hash === '#archive') {
    $('#archive').modal('show')
  }
})
