'use strict'

// Subscriptions table mixin
// Manages the table of subscriptions

const $ = require('jquery')
const { setupImprovedDataTable } = require('../dataTables')
const { encode, postNavigate, getHumanizedDateDiff } = require('../utils')

// userId: ID of the user whose data is displayed
module.exports = ({ userId }) => $(() => {
  const sendAnalyticsData = function (subscriptionId, values) {
    console.log(`Analytics data for ${subscriptionId}: ${values.join(', ')}`)
    $.ajax({
      url: `/subscriptions/${userId}/${subscriptionId}/analyticsEvent`,
      method: 'POST',
      dataType: 'json',
      data: { data: values.concat(['ts:' + Date.now()]).join(',') }
    }).fail(() => {
      console.error('Analytics error')
    })
  }

  const autoExtendSubscriptionId = window.location.hash.startsWith('#extend=') ? Number(window.location.hash.substr(8)) : null
  let initialLoad = true

  setupImprovedDataTable($('#subscriptions-table'), {
    columns: [
      { title: 'Phone Number', data: 'phoneNumber', defaultContent: '', render: (phoneNumber, type, { line_id, label, lineName, isSameNumber, migratedToMdn, migratedFromMdn, connectingDurationColor }) => `<i class="fa fa-circle text-${encode(connectingDurationColor)}"></i> ` + encode(phoneNumber) + (migratedFromMdn ? ` <a href="javascript:;" onclick="$('.migration-guide-modal').modal('show')"><i title="migrated from\n${migratedFromMdn}" class="text-success fa fa-link"></i></a>` : '') + (migratedToMdn ? ` <a href="javascript:;" onclick="$('.migration-guide-modal').modal('show')"><i title="migrated to\n${migratedToMdn}" class="text-danger fa fa-link"></i></a>` : '') + (label ? `<br><small>${encode(label)}</small>` : '') + (window.loggedInUser.isAdmin ? `<br><small ${isSameNumber ? '' : 'style="text-decoration: line-through;"'}>${encode(lineName)}</small>` : '') },
      { title: 'Services', data: 'servicesString', defaultContent: '', className: 'break-word', render: $.fn.dataTable.render.text(), sortable: false },
      { title: 'Start', data: 'start', defaultSort: 'desc', render: (start, type) => `${$.fn.dataTable.render.date()(start, type)}<br><small>${encode(getHumanizedDateDiff(start))}</small>` },
      { title: 'Expiration', data: 'expiration', render: (expiration, type) => `${$.fn.dataTable.render.date()(expiration, type)}<br><small>${encode(getHumanizedDateDiff(expiration))}</small>` },
      { title: 'Status', data: 'status', defaultContent: '', render: (status, type, { migratedToMdn }) => `${encode(status)}${migratedToMdn ? '<br><small>(<a href="javascript:;" onclick="$(\'.migration-guide-modal\').modal(\'show\')">migrated</a>)</small>' : ''}` }
    ],
    createdRow: (row, data, dataIndex) => {
      if (data.status !== 'active') $(row).addClass('text-muted')

      // This is a hackish way to auto-open the extend modal if required. (It's not ideal, but since the action depends on data from the dtAjax endpoint, it makes most sense.)
      if (initialLoad && autoExtendSubscriptionId && data.id === autoExtendSubscriptionId) {
        initialLoad = false
        setTimeout(() => {
          $(row).find('a[title="Extend/Reclaim"]').click()
        }, 0)
      }
    },
    serverSide: true,
    ajax: `/subscriptions/${userId}/dtAjax`,
    buttons: [...userId === window.loggedInUser.id ? [{
      text: 'New Subscription',
      href: '/subscribe',
      className: 'btn-primary'
    }] : [], ...(window.loggedInUser.isAdmin || window.loggedInUser.isCustomerServiceRep) ? [{
      text: 'Subscribe User (Admin Action)',
      href: `/subscriptions/${userId}/create`,
      className: 'btn-info'
    }] : [], $.fn.dataTable.defaults.buttons, {
      text: '$book',
      tooltip: 'SMS History',
      href: data => `/services/${userId}?subscription=${encodeURIComponent(data.id)}`,
      className: 'btn-primary',
      extend: 'selected',
      isRowClickHandler: true
    }, ...(userId === window.loggedInUser.id || window.loggedInUser.isAdmin || window.loggedInUser.isCustomerServiceRep) ? [{
      text: '$calendar-plus-o',
      tooltip: 'Extend/Reclaim',
      action: (e, dt, node, button, row) => {
        const data = row.data()
        const analyticsValues = ['extendModalOpen', 'selected:none', 'status:' + data.status, 'type:' + (data.isPrivateNumber ? 'private' : 'regular')]

        const $modal = $('.extend-modal')
        $modal.data('status', data.status) // Used in global change listener further down
        $modal.data('subscriptionId', data.id)
        $modal.data('sendAnalyticsData', sendAnalyticsData)
        $modal.data('analyticsValues', analyticsValues)

        $modal.find('form').attr('action', `/subscriptions/${userId}/${data.id}/extend`)
        $modal.find('.modal-title').text(`${data.status === 'active' ? 'Extend' : 'Reclaim'} ${data.phoneNumber}`)
        $modal.find('button[type=submit]').text(`${data.status === 'active' ? 'Extend' : 'Reclaim'}`)
        $modal.find('.phone-number').text(data.phoneNumber)
        $modal.find('.services').text(data.servicesString)
        $modal.find('.expiration').text(data.status === 'cancelled' ? '(cancelled)' : `${new Date(data.expiration).toLocaleString()} (${getHumanizedDateDiff(data.expiration)})`)
        $modal.find('.alert.alert-warning').hide()
        if (data.isPrivateNumber) {
          $modal.find('#extend-regular').hide()
          $modal.find('#extend-private').show()
        } else {
          $modal.find('#extend-private').hide()
          $modal.find('#extend-regular').show()
        }

        const optionPrefix = data.isPrivateNumber ? 'p' : 'r'

        Object.values(data.rentalOptions).forEach((el, i) => {
          const requiresMigration = !!(data.maxExtendDuration && el.days >= data.maxExtendDuration)
          const creditsAmount = el.credits.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          const text = !requiresMigration
            ? `${creditsAmount}`
            : `${creditsAmount} (involves migration to new number)`
          $modal.find(`[for="${optionPrefix}-offer${i}"] span`).text(text)
          $modal.find(`[id="${optionPrefix}-offer${i}"]`).data('days', el.days).toggleClass('requires-migration', requiresMigration) // Used in global change listener further down
          if (requiresMigration) {
            if (!analyticsValues.includes('hasMigrationOptions')) analyticsValues.push('hasMigrationOptions')
            analyticsValues.push('migrationOption:' + el.days)
          } else {
            if (!analyticsValues.includes('hasRegularOptions')) analyticsValues.push('hasRegularOptions')
            analyticsValues.push('regularOption:' + el.days)
          }
        })

        if (data.maxExtendDuration != null) {
          const rentalOptionOverMaxDuration = Object.values(data.rentalOptions).sort((a, b) => a.days - b.days).find(el => el.days > data.maxExtendDuration)
          if (rentalOptionOverMaxDuration) {
            if (!window.loggedInUser.isAdmin) {
              $modal.find('.alert.alert-warning.null').html(`<p>This extension requires you to transfer your service to a new number within 24 hours.</p><a href="#" class="migration-guide">Click here</a> for our migration guide.`)
            } else {
              $modal.find('.alert.alert-warning.null').html(`<p>This extension requires a service migration by the user within 24 hours of extending the subscription.</p><a href="#" class="migration-guide"><br>Click here</a> for our migration guide.`)
            }
            $modal.find('.migration-guide').click(() => {
              $modal.modal('hide')
              $('.migration-guide-modal').modal('show')
              $('.migration-guide-modal').one('shown.bs.modal', () => {
                analyticsValues[0] = 'migrationHelpOpen'
                sendAnalyticsData(data.id, analyticsValues)
              }).one('hidden.bs.modal', () => {
                analyticsValues[0] = 'extendModalOpen'
                sendAnalyticsData(data.id, analyticsValues)
                $modal.modal('show')
              })
            })
          }
        }

        $modal.find('.radio-group :checked').prop('checked', false)
        $modal.find('button[type=submit]').prop('disabled', true)

        $modal.modal('show')

        sendAnalyticsData(data.id, analyticsValues)
      },
      className: 'btn-warning',
      extend: 'selected',
      disabled: data => !data.extendable
    }, {
      text: '$play Select for Realtime SMS',
      tooltip: 'Select for Realtime SMS',
      action: (e, dt, node, button, row) => postNavigate(`/subscriptions/${userId}/${row.data().id}/activate`),
      className: 'dropdown-item',
      extend: 'selected',
      subMenu: '$ellipsis-v',
      disabled: data => data.status !== 'active'
    }] : [], {
      text: '$pencil-square-o Rename',
      tooltip: 'Rename',
      action: (e, dt, node, button, row) => {
        const data = row.data()

        const $modal = $('.rename-modal')
        $modal.find('form').attr('action', `/subscriptions/${userId}/${data.id}/rename`)
        $modal.find('.subscription-info').text(`${data.phoneNumber} (${data.servicesString})`)
        $modal.find('[name=label]').val(data.label)

        $modal.modal('show')
      },
      className: 'dropdown-item',
      extend: 'selected',
      subMenu: '$ellipsis-v'
    }, ...(window.loggedInUser.isAdmin || window.loggedInUser.isCustomerServiceRep) ? [{
      text: '$unlink Terminate',
      tooltip: 'Terminate',
      action: (e, dt, node, button, row) => {
        const data = row.data()

        const $modal = $('.terminate-subscription-modal')
        $modal.find('form').attr('action', `/subscriptions/${userId}/${data.id}/terminate`)
        $modal.find('.subscription-info').text(`${data.phoneNumber} (${data.servicesString})`)
        $modal.find('[name=refundAmount]').prop('placeholder', data.refundAmount)

        $modal.modal('show')
      },
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => data.status !== 'active',
      subMenu: '$ellipsis-v'
    }, {
      text: '$exchange Change services',
      tooltip: 'Change services',
      action: (e, dt, node, button, row) => {
        const data = row.data()

        const $modal = $('.change-services-modal')
        $modal.find('form').attr('action', `/subscriptions/${userId}/${data.id}/changeServices`)
        $modal.find('.subscription-info').text(`${data.phoneNumber} (${data.servicesString})`)

        const $serviceSelect = $modal.find('[name=subscriptionServices]')
        const prevServices = (data.services || '').split(/\s+/).filter(s => s)

        const $submitBtn = $modal.find('button[type=submit]')
        $submitBtn.prop('disabled', true)

        const updateSubmitBtn = () => {
          const selectedServices = $serviceSelect.val()
          $submitBtn.prop('disabled', !selectedServices || selectedServices.length === 0)
        }

        $serviceSelect.selectpicker('val', prevServices)
        $serviceSelect.find('option').prop('disabled', true).data('subtext', '')
        $serviceSelect.selectpicker('refresh')

        $serviceSelect.change(updateSubmitBtn)

        $.ajax({
          contentType: 'application/json',
          method: 'POST',
          url: '/services',
          data: JSON.stringify({
            phoneNumber: data.phoneNumber
          }),
          dataType: 'json'
        }).then(
          (res) => {
            /* res = {
              zipcodes: ['00001', '00002', ...],
              usecases: ['FACEBOOK', 'TWITTER', ...]
            } */
            $serviceSelect.find('option').data('subtext', 'already used in line')
            for (const service of res.usecases) $serviceSelect.find('.usecase-' + service).prop('disabled', false).data('subtext', '')
            for (const service of prevServices) $serviceSelect.find('.usecase-' + service).prop('disabled', false).data('subtext', 'current selection')
            $serviceSelect.selectpicker('refresh')

            updateSubmitBtn()
          },
          () => {
            window.alert('A connection error has occurred, please reload the page.')
          }
        )

        $modal.modal('show')
      },
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => data.isPrivateNumber,
      subMenu: '$ellipsis-v'
    }, {
      text: '$phone-square Change credits worth',
      tooltip: 'Change credits worth',
      action: (e, dt, node, button, row) => {
        const data = row.data()

        const $modal = $('.change-credits-worth-modal')
        $modal.find('form').attr('action', `/subscriptions/${userId}/${data.id}/changeCreditsWorth`)
        $modal.find('.subscription-info').text(`${data.phoneNumber} (${data.servicesString})`)
        $modal.find('[name=creditsWorthUser]').val(data.refundAmount)
        $modal.find('[name=creditsWorthReseller]').val(data.resellerRefundAmount)

        $modal.modal('show')
      },
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => data.status !== 'active',
      subMenu: '$ellipsis-v'
    }, {
      text: '$calendar-check-o Change expiration',
      tooltip: 'Change expiration',
      action: (e, dt, node, button, row) => {
        const data = row.data()

        // Get date as a string suitable for the datetime-local field, converted to local timezone in format 2000-01-01T00:00
        const pad = n => String(n).padStart(2, '0')
        const convertDate = d => `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}`

        const $modal = $('.change-expiration-modal')
        $modal.find('form').attr('action', `/subscriptions/${userId}/${data.id}/changeExpiration`)
        $modal.find('.subscription-info').text(`${data.phoneNumber} (${data.servicesString})`)
        $modal.find('[name=expirationDt]').val(convertDate(new Date(data.expiration))).attr('min', convertDate(new Date(data.start)))
        $modal.find('[name=expiration]').val(new Date(data.expiration).toISOString())

        $modal.modal('show')
      },
      className: 'dropdown-item',
      extend: 'selected',
      disabled: data => data.status !== 'active',
      subMenu: '$ellipsis-v'
    }] : [], ...window.loggedInUser.isAdmin ? [{
      text: '$cog Agent line management page',
      tooltip: 'Agent line management page',
      href: data => `/agentLineManagement/${data.line_id}`,
      className: 'dropdown-item',
      extend: 'selected',
      subMenu: '$ellipsis-v'
    }] : []],
    search: autoExtendSubscriptionId ? { search: '#' + autoExtendSubscriptionId } : {}
  })

  const $extendModal = $('.extend-modal')
  $extendModal.find('input[name="duration"]').change(function () {
    const requiresMigration = $(this).hasClass('requires-migration')
    $extendModal.find('.alert.alert-warning.null').toggle(requiresMigration)
    $extendModal.find('button[type=submit]')
      .prop('disabled', false)
      .text(`${$extendModal.data('status') === 'active' ? 'Extend' : 'Reclaim'}${requiresMigration ? ' and migrate' : ''}`)
    const sendAnalyticsData = $extendModal.data('sendAnalyticsData')
    const subscriptionId = $extendModal.data('subscriptionId')
    const analyticsValues = $extendModal.data('analyticsValues')
    analyticsValues[1] = 'selected:' + $(this).data('days')
    sendAnalyticsData(subscriptionId, analyticsValues)
  })
  $extendModal.on('hidden.bs.modal', function () {
    const sendAnalyticsData = $extendModal.data('sendAnalyticsData')
    const subscriptionId = $extendModal.data('subscriptionId')
    const analyticsValues = $extendModal.data('analyticsValues')
    analyticsValues[0] = 'extendModalClosed'
    sendAnalyticsData(subscriptionId, analyticsValues)
  })
  $extendModal.find('form').on('submit', function () {
    const analyticsValues = $extendModal.data('analyticsValues')
    const $checked = $extendModal.find('input[name="duration"]:checked')
    const days = $checked.data('days')
    const withMigration = $checked.hasClass('requires-migration')
    analyticsValues[0] = 'executedExtend'
    analyticsValues.push('executedDays:' + days)
    analyticsValues.push(withMigration ? 'withMigration' : 'withoutMigration')
    $(this).find('[name=analyticsData]').val(analyticsValues.concat('ts:' + Date.now()).join(','))
  })

  $('.change-expiration-modal [name=expirationDt]').change(function () {
    $('.change-expiration-modal [name=expiration]').val(new Date($(this).val()).toISOString())
  })
})
