<script>
  import { linkify } from '../../assets/js/utils.js'

  export let message
  export let msgType = null
</script>

<style>
  .body {
    white-space: pre-wrap;
    word-break: break-word;
  }
</style>

{#if message.subject}
  <h4>{message.subject}</h4>
{/if}

<div class="headers">
  <small>
    {#if msgType === 'inbox'}
      From <strong>{message.sender}</strong>
    {:else if msgType === 'sent'}
      To <strong>{message.recipient}</strong>
    {:else}
      From <strong>{message.sender}</strong> to <strong>{message.recipient}</strong>
    {/if}

    on {new Date(message.timestamp).toLocaleString()}
  </small>
</div>

<div class="body" use:linkify>
  {message.text}
</div>
