<script>
  import { formatDuration } from '../../../utils/usecasesTools';
  import PricingPerDurationTable from '../PricingPerDurationTable/index.svelte'

  export let hasDefault = true
  export let hasPreventNegative = true
  export let tiers = []
  export let allowEmpty = false
  export let defaultServiceTier = {price: 1}
  export let durations


  function generateRandomTierId () {
    return Date.now() + Math.random().toString(36).substring(2)
  }

  function createNewTier () {
    return {name: '', isDefaultTier: false, id: generateRandomTierId(), preventNegative: true}
  }

  if (hasDefault) {
    if (!defaultServiceTier) defaultServiceTier = {price: 1}
    tiers.push({name: 'default', isDefaultTier: true, id: generateRandomTierId(), ...defaultServiceTier})
  }

  function createTableRow (idx) {
    tiers = tiers.toSpliced(idx, 0, createNewTier())
  }

  function removeTableRow (idx, isDefaultTier) {
    if (isDefaultTier) {
      return
    }
    tiers = tiers.toSpliced(idx, 1)
  }

  function changeDefaultTier (id, event) {
    if (tiers.find(el => el.id === id).isDefaultTier) return

    if(confirm('Changing your default service tier will remove all service assignments that used this tier from the list.\n\nAre you sure?')) {
      tiers = tiers.map((el, idx) => {
        const tier = {...el, isDefaultTier: el.id === id}
        if (el.id === id) defaultServiceTier = tier
        return tier
      })
    } else {
      event.preventDefault()
    }
  }

  function checkforProblems () {
    const nameList = {}
    for (const tier of tiers) {
      const name = tier.name
      if (nameList[name]) {
        [nameList[name], tier].forEach( tier => tier.hasWarning = true)
      } else {
        tier.hasWarning = false
        nameList[name] = tier
      }
    }
  }

  function changePreventNegative (event, preventNegative) {
    if(preventNegative && !confirm('Changing this setting will margins make negative margins possible.\n\nChange at your own risk!')) {
      event.preventDefault()
    }
  }

  function openDurationsModal (idx) {
    const tier = tiers[idx]

  }

  $: tiers, checkforProblems()
</script>


<h3>Service Tiers</h3>
<small class="form-help text-muted">Service tiers define how many credits it takes to rent a service. No two tiers can have the same name.</small>

<div class="service-tier-options-editor clearfix">
  <table class="table">
    <thead>
      <tr>
        <th>Service Tier</th>
        {#each durations as duration}
        <th class="text-center">{formatDuration(duration)}</th>
        {/each}
        {#if hasDefault}
          <th>Default</th>
        {/if}
        <th style="width: 180px"></th>
      </tr>
    </thead>
    <tbody>
      {#each tiers as {name: name = '', id, hasWarning: hasWarning = false, isDefaultTier, preventNegative, ...pricing}, i (id)}
        <tr class:default={isDefaultTier} data-id="{id}">
          <td class:bg-warning={hasWarning}><input required class="tier-field form-control text-right " bind:value={name}/></td>
          {#each durations as duration}
          <td class="position-relative text-center h5">
            <input class="functionally-invisible" value={pricing[duration] || ''} required={isDefaultTier} name='_frontendPriceValidation'> <span>{pricing[duration] ? pricing[duration].toFixed(2) : 'N/A'} <i class="fa fa-phone-square"></i></span>
          </td>
          {/each}
          {#if hasDefault}
            <td class="d-flex">
              <label><input class="default-tier-field" name="defaultServiceTierRadio" bind:value={name} type="radio" checked={isDefaultTier} on:click={event => changeDefaultTier(id, event)}/> Default Tier </label>
            </td>
          {/if}
          <td>
            <button type="button" title="Edit" class="btn btn-primary mr-2" data-toggle="modal" data-target={`#modal-${id}`}><i class="fa fa-pencil-square-o"></i></button>

            <div class="modal fade" id={`modal-${id}`} tabindex="-1" role="dialog" aria-labelledby="durations-title-{id}" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="durations-title-{id}">{name || 'Unnamed Service Tier'}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <PricingPerDurationTable {durations} bind:serviceTier={tiers[i]} preventNegative={tiers[i].preventNegative} priceType="base" />
                    {#if hasPreventNegative}
                      <div class="mt-2">
                        <label><input class="negative-tier-field" type="checkbox" bind:checked={preventNegative} on:click={event => changePreventNegative(event, preventNegative)}/> Prevent Negative Markup</label>
                      </div>
                    {/if}
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Confirm</button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" title="Add" class="add-btn btn btn-outline-info" on:click={() => createTableRow(i)}><i class="fa fa-plus"></i></button>
            <button type="button" title="Remove" disabled={isDefaultTier || !allowEmpty && tiers.length === 1} class="remove-btn btn btn-outline-danger" on:click={() => removeTableRow(i, isDefaultTier)}><i class="fa fa-minus"></i></button>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
  <button type="button" title="Add" class="pull-right add-btn btn btn-outline-info" on:click={ event => tiers = [...tiers, createNewTier()] }><i class="fa fa-plus"></i> Add row</button>
</div>



<style>
  .functionally-invisible {
    opacity: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
  }
</style>
